import eyebrow1left from '../img/eyebrow/eyebrow1-left.svg';
import eyebrow1right from '../img/eyebrow/eyebrow1-right.svg';
import eyebrow2left from '../img/eyebrow/eyebrow2-left.svg';
import eyebrow2right from '../img/eyebrow/eyebrow2-right.svg';
import eyebrow3left from '../img/eyebrow/eyebrow3-left.svg';
import eyebrow3right from '../img/eyebrow/eyebrow3-right.svg';
import eyebrow4left from '../img/eyebrow/eyebrow4-left.svg';
import eyebrow4right from '../img/eyebrow/eyebrow4-right.svg';
import eyebrow5left from '../img/eyebrow/eyebrow5-left.svg';
import eyebrow5right from '../img/eyebrow/eyebrow5-right.svg';
import eyebrow6left from '../img/eyebrow/eyebrow6-left.svg';
import eyebrow6right from '../img/eyebrow/eyebrow6-right.svg';
import eyebrow7left from '../img/eyebrow/eyebrow7-left.svg';
import eyebrow7right from '../img/eyebrow/eyebrow7-right.svg';
import eyebrow8left from '../img/eyebrow/eyebrow8-left.svg';
import eyebrow8right from '../img/eyebrow/eyebrow8-right.svg';
import eyebrow9left from '../img/eyebrow/eyebrow9-left.svg';
import eyebrow10right from '../img/eyebrow/eyebrow10-right.svg';
import eyebrow11left from '../img/eyebrow/eyebrow11-left.svg';
import eyebrow12right from '../img/eyebrow/eyebrow12-right.svg';
import eyebrow13right from '../img/eyebrow/eyebrow13-right.svg';
import eyebrow14left from '../img/eyebrow/eyebrow14-left.svg';
import eyebrow15left from '../img/eyebrow/eyebrow15-left.svg';
import eyebrow15right from '../img/eyebrow/eyebrow15-right.svg';
import eyebrow16left from '../img/eyebrow/eyebrow16-left.svg';
import eyebrow16right from '../img/eyebrow/eyebrow16-right.svg';
import eyebrow17left from '../img/eyebrow/eyebrow17-left.svg';
import eyebrow17right from '../img/eyebrow/eyebrow17-right.svg';
import PartsInfo from "../type/PartsInfo";

const GetEyebrowMenuList = () => {
  let eyebrowMenuList:PartsInfo[] = [
    {
      pass:eyebrow1left,
      name:"eyebrow1-left",
      width:140,
      height:58,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow1right,
      name:"eyebrow1-right",
      width:140,
      height:58,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow2left,
      name:"eyebrow2-left",
      width:140,
      height:56,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow2right,
      name:"eyebrow2-right",
      width:140,
      height:56,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow3left,
      name:"eyebrow3-left",
      width:140,
      height:71,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow3right,
      name:"eyebrow3-right",
      width:140,
      height:71,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow4left,
      name:"eyebrow4-left",
      width:125,
      height:84,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow4right,
      name:"eyebrow4-right",
      width:125,
      height:84,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow5left,
      name:"eyebrow5-left",
      width:112,
      height:97,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow5right,
      name:"eyebrow5-right",
      width:112,
      height:97,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow6left,
      name:"eyebrow6-left",
      width:125,
      height:112,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow6right,
      name:"eyebrow6-right",
      width:125,
      height:112,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow7left,
      name:"eyebrow7-left",
      width:140,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow7right,
      name:"eyebrow7-right",
      width:140,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow8left,
      name:"eyebrow8-left",
      width:125,
      height:84,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow8right,
      name:"eyebrow8-right",
      width:139,
      height:71,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow9left,
      name:"eyebrow9-left",
      width:122,
      height:33,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow10right,
      name:"eyebrow10-right",
      width:122,
      height:38,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow11left,
      name:"eyebrow11-left",
      width:137,
      height:46,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow12right,
      name:"eyebrow12-right",
      width:170,
      height:86,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow13right,
      name:"eyebrow13-right",
      width:104,
      height:109,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow14left,
      name:"eyebrow14-left",
      width:132,
      height:64,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow15left,
      name:"eyebrow15-left",
      width:125,
      height:83,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow15right,
      name:"eyebrow15-right",
      width:125,
      height:83,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow16left,
      name:"eyebrow16-left",
      width:125,
      height:83,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow16right,
      name:"eyebrow16-right",
      width:125,
      height:83,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow17left,
      name:"eyebrow17-left",
      width:140,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eyebrow17right,
      name:"eyebrow17-right",
      width:140,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
  ]
  return eyebrowMenuList
}

export default GetEyebrowMenuList;
