import base1 from '../img/base/base1.svg';
import base2 from '../img/base/base2.svg';
import base3 from '../img/base/base3.svg';
import base4 from '../img/base/base4.svg';
import base5 from '../img/base/base5.svg';
import base6 from '../img/base/base6.svg';
import base7 from '../img/base/base7.svg';
import base8 from '../img/base/base8.svg';
import base9 from '../img/base/base9.svg';
import base10 from '../img/base/base10.svg';
import base11 from '../img/base/base11.svg';
import PartsInfo from "../type/PartsInfo";

const GetBaseMenuList = () => {
  let baseMenuList:PartsInfo[] = [
    {
      pass:base1,
      name:"base1",
      width:500,
      height:500,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:base2,
      name:"base2",
      width:500,
      height:500,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:base3,
      name:"base3",
      width:500,
      height:500,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:base4,
      name:"base4",
      width:500,
      height:500,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:base5,
      name:"base5",
      width:500,
      height:500,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:base6,
      name:"base6",
      width:500,
      height:500,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:base7,
      name:"base7",
      width:500,
      height:500,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:base8,
      name:"base8",
      width:472,
      height:498,
      x:14,
      y:2,
      key: 0,
      target: false
    },
    {
      pass:base9,
      name:"base9",
      width:500,
      height:500,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:base10,
      name:"base10",
      width:500,
      height:500,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:base11,
      name:"base11",
      width:500,
      height:500,
      x:0,
      y:0,
      key: 0,
      target: false
    },
  ]
  return baseMenuList
}

export default GetBaseMenuList;
