// import { ref, uploadBytes} from "firebase/storage";
import { ref, uploadBytes, getDownloadURL} from "firebase/storage";
import {GetStorage,GetDB} from './Firebase';
import {setDoc, doc, collection} from 'firebase/firestore/lite';

const storage = GetStorage()
const db = GetDB();

const PostImage = async(fileName:string, dataUrl:string, valence:number, arousal:number,textAreaValue:string,emojicode:string) => {
    let today:Date = new Date();
    let year:number = today.getFullYear();
    let month:number = today.getMonth()+1;
    let month2:string = month<10 ? "0"+month.toString() : month.toString();
    let day:number = today.getDate();
    let day2:string = day<10 ? "0"+day.toString() : day.toString();
    let hour:number = today.getHours();
    let hour2:string = hour<10 ? "0"+hour.toString() : hour.toString();
    let minute:number = today.getMinutes();
    let minute2:string = minute<10 ? "0"+minute.toString() : minute.toString();
    let second:number = today.getSeconds();
    let second2:string = second<10 ? "0"+second.toString() : second.toString();
    let date:string = `${year}年${month2}月`;
    let time:string = `${year}年${month2}月${day2}日${hour2}時${minute2}分${second2}秒`;
    // fileName = fileName==="" ? "none" : fileName;
    // const saveImagesRef = ref(storage, fileName+"_"+date);
    const saveImagesRef = await ref(storage, "img"+time);
        fetch(dataUrl)
        .then(response => response.blob())
        .then(blob => {
            uploadBytes(saveImagesRef, blob).then(() => {
                console.log("success")
                getDownloadURL(saveImagesRef)
                .then(function (downloadURL: string) {
                    let data = {
                        valence: valence,
                        arousal: arousal,
                        text: textAreaValue,
                        emojicode: emojicode,
                        time:time,
                        url: downloadURL
                    }
                    try{
                        setDoc(doc(collection(db,date)),data)
                        console.log("データベースに保存しました")
                    }catch (e) {
                        console.log("エラー",e)
                    }
                });
            })
            .catch(() =>{
                console.log("error")
            });
        })
}

export default PostImage;