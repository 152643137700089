import emoji1 from '../img/emoji/emoji1.svg';
import emoji2 from '../img/emoji/emoji2.svg';
import emoji3 from '../img/emoji/emoji3.svg';
import emoji4 from '../img/emoji/emoji4.svg';
import emoji5 from '../img/emoji/emoji5.svg';
import emoji6 from '../img/emoji/emoji6.svg';
import emoji7 from '../img/emoji/emoji7.svg';
import emoji8 from '../img/emoji/emoji8.svg';
import emoji9 from '../img/emoji/emoji9.svg';
import emoji10 from '../img/emoji/emoji10.svg';
import emoji11 from '../img/emoji/emoji11.svg';
import emoji12 from '../img/emoji/emoji12.svg';
import emoji13 from '../img/emoji/emoji13.svg';
import emoji14 from '../img/emoji/emoji14.svg';
import emoji15 from '../img/emoji/emoji15.svg';
import emoji16 from '../img/emoji/emoji16.svg';
import emoji17 from '../img/emoji/emoji17.svg';
import emoji18 from '../img/emoji/emoji18.svg';
import emoji19 from '../img/emoji/emoji19.svg';
import emoji20 from '../img/emoji/emoji20.svg';
import emoji21 from '../img/emoji/emoji21.svg';
import emoji22 from '../img/emoji/emoji22.svg';
import emoji23 from '../img/emoji/emoji23.svg';
import emoji24 from '../img/emoji/emoji24.svg';
import emoji25 from '../img/emoji/emoji25.svg';
import emoji26 from '../img/emoji/emoji26.svg';
import emoji27 from '../img/emoji/emoji27.svg';
import emoji28 from '../img/emoji/emoji28.svg';
import emoji29 from '../img/emoji/emoji29.svg';
import emoji30 from '../img/emoji/emoji30.svg';
import emoji31 from '../img/emoji/emoji31.svg';
import emoji32 from '../img/emoji/emoji32.svg';
import emoji33 from '../img/emoji/emoji33.svg';
import emoji34 from '../img/emoji/emoji34.svg';
import emoji35 from '../img/emoji/emoji35.svg';
import emoji36 from '../img/emoji/emoji36.svg';
import emoji37 from '../img/emoji/emoji37.svg';
import emoji38 from '../img/emoji/emoji38.svg';
import emoji39 from '../img/emoji/emoji39.svg';
import emoji40 from '../img/emoji/emoji40.svg';
import emoji41 from '../img/emoji/emoji41.svg';
import emoji42 from '../img/emoji/emoji42.svg';
import emoji43 from '../img/emoji/emoji43.svg';
import emoji44 from '../img/emoji/emoji44.svg';
import emoji45 from '../img/emoji/emoji45.svg';
import emoji46 from '../img/emoji/emoji46.svg';
import emoji47 from '../img/emoji/emoji47.svg';
import emoji48 from '../img/emoji/emoji48.svg';
import emoji49 from '../img/emoji/emoji49.svg';
import emoji50 from '../img/emoji/emoji50.svg';
import emoji51 from '../img/emoji/emoji51.svg';
import emoji52 from '../img/emoji/emoji52.svg';
import emoji53 from '../img/emoji/emoji53.svg';
import emoji54 from '../img/emoji/emoji54.svg';
import emoji55 from '../img/emoji/emoji55.svg';
import emoji56 from '../img/emoji/emoji56.svg';
import emoji57 from '../img/emoji/emoji57.svg';
import emoji58 from '../img/emoji/emoji58.svg';
import emoji59 from '../img/emoji/emoji59.svg';
import emoji60 from '../img/emoji/emoji60.svg';
import emoji61 from '../img/emoji/emoji61.svg';
import emoji62 from '../img/emoji/emoji62.svg';
import emoji63 from '../img/emoji/emoji63.svg';
import emoji64 from '../img/emoji/emoji64.svg';
import emoji65 from '../img/emoji/emoji65.svg';
import emoji66 from '../img/emoji/emoji66.svg';
import emoji67 from '../img/emoji/emoji67.svg';
import emoji68 from '../img/emoji/emoji68.svg';
import emoji69 from '../img/emoji/emoji69.svg';
import emoji70 from '../img/emoji/emoji70.svg';
import emoji71 from '../img/emoji/emoji71.svg';
import emoji72 from '../img/emoji/emoji72.svg';
import emoji73 from '../img/emoji/emoji73.svg';
import emoji74 from '../img/emoji/emoji74.svg';

const GetEmojiMenuList = () => {
  let emojiMenuList = [
    {
      pass:emoji1,
      name:"😀",
      valence:7.51,
      arousal:5.87
    },
    {
      pass:emoji2,
      name:"😃",
      valence:7.32,
      arousal:5.80
    },
    {
      pass:emoji3,
      name:"😄",
      valence:7.70,
      arousal:7.02
    },
    {
      pass:emoji4,
      name:"😁",
      valence:7.83,
      arousal:7.32
    },
    {
      pass:emoji5,
      name:"😆",
      valence:7.59,
      arousal:7.46
    },
    {
      pass:emoji6,
      name:"😅",
      valence:4.26,
      arousal:5.04
    },
    {
      pass:emoji7,
      name:"🤣",
      valence:6.51,
      arousal:7.29
    },
    {
      pass:emoji8,
      name:"🙂",
      valence:6.02,
      arousal:4.73
    },
    {
      pass:emoji9,
      name:"😉",
      valence:6.54,
      arousal:5.68
    },
    {
      pass:emoji10,
      name:"😊",
      valence:7.50,
      arousal:6.77
    },
    {
      pass:emoji11,
      name:"😇",
      valence:6.10,
      arousal:5.59
    },
    {
      pass:emoji12,
      name:"🥰",
      valence:7.69,
      arousal:7.37
    },
    {
      pass:emoji13,
      name:"😍",
      valence:7.78,
      arousal:7.95
    },
    {
      pass:emoji14,
      name:"🤩",
      valence:7.30,
      arousal:7.40
    },
    {
      pass:emoji15,
      name:"😘",
      valence:7.08,
      arousal:6.87
    },
    {
      pass:emoji16,
      name:"😗",
      valence:4.92,
      arousal:4.63
    },
    {
      pass:emoji17,
      name:"☺️",
      valence:7.75,
      arousal:7.03
    },
    {
      pass:emoji18,
      name:"😚",
      valence:7.12,
      arousal:7.04
    },
    {
      pass:emoji19,
      name:"😙",
      valence:6.58,
      arousal:6.00
    },
    {
      pass:emoji20,
      name:"😋",
      valence:6.87,
      arousal:6.21
    },
    {
      pass:emoji21,
      name:"😛",
      valence:5.47,
      arousal:5.50
    },
    {
      pass:emoji22,
      name:"😜",
      valence:5.49,
      arousal:6.20
    },
    {
      pass:emoji23,
      name:"😝",
      valence:6.41,
      arousal:6.51
    },
    {
      pass:emoji24,
      name:"🤗",
      valence:6.29,
      arousal:5.94
    },
    {
      pass:emoji25,
      name:"🤭",
      valence:5.70,
      arousal:5.43
    },
    {
      pass:emoji26,
      name:"🤔",
      valence:4.83,
      arousal:5.14
    },
    {
      pass:emoji27,
      name:"🤨",
      valence:4.00,
      arousal:5.08
    },
    {
      pass:emoji28,
      name:"😐️",
      valence:4.22,
      arousal:4.62
    },
    {
      pass:emoji29,
      name:"😑",
      valence:3.78,
      arousal:4.92
    },
    {
      pass:emoji30,
      name:"😶",
      valence:4.80,
      arousal:4.24
    },
    {
      pass:emoji31,
      name:"😒",
      valence:3.29,
      arousal:5.43
    },
    {
      pass:emoji32,
      name:"🙄",
      valence:4.36,
      arousal:5.13
    },
    {
      pass:emoji33,
      name:"😬",
      valence:4.03,
      arousal:5.19
    },
    {
      pass:emoji34,
      name:"😌",
      valence:6.11,
      arousal:5.07
    },
    {
      pass:emoji35,
      name:"😔",
      valence:3.52,
      arousal:5.50
    },
    {
      pass:emoji36,
      name:"😪",
      valence:4.22,
      arousal:4.94
    },
    {
      pass:emoji37,
      name:"🤢",
      valence:2.39,
      arousal:6.78
    },
    {
      pass:emoji38,
      name:"🥵",
      valence:2.52,
      arousal:6.82
    },
    {
      pass:emoji39,
      name:"🥶",
      valence:2.21,
      arousal:7.32
    },
    {
      pass:emoji40,
      name:"🥴",
      valence:4.50,
      arousal:5.02
    },
    {
      pass:emoji41,
      name:"😵",
      valence:4.04,
      arousal:5.93
    },
    {
      pass:emoji42,
      name:"🤯",
      valence:4.45,
      arousal:6.20
    },
    {
      pass:emoji43,
      name:"🤠",
      valence:5.70,
      arousal:4.78
    },
    {
      pass:emoji44,
      name:"🥳",
      valence:7.13,
      arousal:6.78
    },
    {
      pass:emoji45,
      name:"😎",
      valence:5.62,
      arousal:5.06
    },
    {
      pass:emoji46,
      name:"🧐",
      valence:5.20,
      arousal:5.14
    },
    {
      pass:emoji47,
      name:"😕",
      valence:4.01,
      arousal:4.69
    },
    {
      pass:emoji48,
      name:"😟",
      valence:3.47,
      arousal:5.71
    },
    {
      pass:emoji49,
      name:"🙁",
      valence:4.13,
      arousal:4.64
    },
    {
      pass:emoji50,
      name:"☹️",
      valence:3.88,
      arousal:5.01
    },
    {
      pass:emoji51,
      name:"😮",
      valence:5.18,
      arousal:5.52
    },
    {
      pass:emoji52,
      name:"😯",
      valence:5.17,
      arousal:4.82
    },
    {
      pass:emoji53,
      name:"😲",
      valence:5.09,
      arousal:5.24
    },
    {
      pass:emoji54,
      name:"😳",
      valence:5.50,
      arousal:5.65
    },
    {
      pass:emoji55,
      name:"🥺",
      valence:4.12,
      arousal:5.13
    },
    {
      pass:emoji56,
      name:"😦",
      valence:4.45,
      arousal:4.36
    },
    {
      pass:emoji57,
      name:"😧",
      valence:4.41,
      arousal:4.55
    },
    {
      pass:emoji58,
      name:"😨",
      valence:3.25,
      arousal:5.98
    },
    {
      pass:emoji59,
      name:"😰",
      valence:2.88,
      arousal:6.53
    },
    {
      pass:emoji60,
      name:"😥",
      valence:3.48,
      arousal:5.68
    },
    {
      pass:emoji61,
      name:"😢",
      valence:3.56,
      arousal:5.58
    },
    {
      pass:emoji62,
      name:"😭",
      valence:2.97,
      arousal:7.00
    },
    {
      pass:emoji63,
      name:"😱",
      valence:3.36,
      arousal:7.03
    },
    {
      pass:emoji64,
      name:"😖",
      valence:3.44,
      arousal:6.28
    },
    {
      pass:emoji65,
      name:"😣",
      valence:3.39,
      arousal:6.09
    },
    {
      pass:emoji66,
      name:"😞",
      valence:3.86,
      arousal:4.79
    },
    {
      pass:emoji67,
      name:"😓",
      valence:3.93,
      arousal:4.90
    },
    {
      pass:emoji68,
      name:"😩",
      valence:3.02,
      arousal:6.31
    },
    {
      pass:emoji69,
      name:"😫",
      valence:3.01,
      arousal:6.70
    },
    {
      pass:emoji70,
      name:"😤",
      valence:3.02,
      arousal:6.69
    },
    {
      pass:emoji71,
      name:"😡",
      valence:2.09,
      arousal:7.53
    },
    {
      pass:emoji72,
      name:"😠",
      valence:2.97,
      arousal:6.80
    },
    {
      pass:emoji73,
      name:"🤬",
      valence:2.39,
      arousal:7.48
    },
    {
      pass:emoji74,
      name:"🤡",
      valence:5.41,
      arousal:4.99
    },
    
  ]
  return emojiMenuList
}

export default GetEmojiMenuList;
