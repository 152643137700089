import base1 from '../img/base/base1.svg';
import base2 from '../img/base/base2.svg';
import base3 from '../img/base/base3.svg';
import base4 from '../img/base/base4.svg';
import base5 from '../img/base/base5.svg';
import base6 from '../img/base/base6.svg';
import base7 from '../img/base/base7.svg';
import base8 from '../img/base/base8.svg';
import base9 from '../img/base/base9.svg';
import base11 from '../img/base/base11.svg';
import eyebrow1left from '../img/eyebrow/eyebrow1-left.svg';
import eyebrow1right from '../img/eyebrow/eyebrow1-right.svg';
import eyebrow2left from '../img/eyebrow/eyebrow2-left.svg';
import eyebrow2right from '../img/eyebrow/eyebrow2-right.svg';
import eyebrow3left from '../img/eyebrow/eyebrow3-left.svg';
import eyebrow3right from '../img/eyebrow/eyebrow3-right.svg';
import eyebrow4left from '../img/eyebrow/eyebrow4-left.svg';
import eyebrow4right from '../img/eyebrow/eyebrow4-right.svg';
import eyebrow5left from '../img/eyebrow/eyebrow5-left.svg';
import eyebrow5right from '../img/eyebrow/eyebrow5-right.svg';
import eyebrow6left from '../img/eyebrow/eyebrow6-left.svg';
import eyebrow6right from '../img/eyebrow/eyebrow6-right.svg';
import eyebrow7left from '../img/eyebrow/eyebrow7-left.svg';
import eyebrow7right from '../img/eyebrow/eyebrow7-right.svg';
import eyebrow8left from '../img/eyebrow/eyebrow8-left.svg';
import eyebrow8right from '../img/eyebrow/eyebrow8-right.svg';
import eyebrow9left from '../img/eyebrow/eyebrow9-left.svg';
import eyebrow10right from '../img/eyebrow/eyebrow10-right.svg';
import eyebrow11left from '../img/eyebrow/eyebrow11-left.svg';
import eyebrow12right from '../img/eyebrow/eyebrow12-right.svg';
import eyebrow13right from '../img/eyebrow/eyebrow13-right.svg';
import eyebrow14left from '../img/eyebrow/eyebrow14-left.svg';
import eyebrow15left from '../img/eyebrow/eyebrow15-left.svg';
import eyebrow15right from '../img/eyebrow/eyebrow15-right.svg';
import eyebrow16left from '../img/eyebrow/eyebrow16-left.svg';
import eyebrow16right from '../img/eyebrow/eyebrow16-right.svg';
import eyebrow17left from '../img/eyebrow/eyebrow17-left.svg';
import eyebrow17right from '../img/eyebrow/eyebrow17-right.svg';
import eye1 from '../img/eye/eye1.svg';
import eye2 from '../img/eye/eye2.svg';
import eye3 from '../img/eye/eye3.svg';
import eye4 from '../img/eye/eye4.svg';
import eye5left from '../img/eye/eye5-left.svg';
import eye5right from '../img/eye/eye5-right.svg';
import eye6left from '../img/eye/eye6-left.svg';
import eye6right from '../img/eye/eye6-right.svg';
import eye7 from '../img/eye/eye7.svg';
import eye8 from '../img/eye/eye8.svg';
import eye9left from '../img/eye/eye9-left.svg';
import eye9right from '../img/eye/eye9-right.svg';
import eye10 from '../img/eye/eye10.svg';
import eye11 from '../img/eye/eye11.svg';
import eye12 from '../img/eye/eye12.svg';
import eye13 from '../img/eye/eye13.svg';
import eye14 from '../img/eye/eye14.svg';
import eye15 from '../img/eye/eye15.svg';
import eye16 from '../img/eye/eye16.svg';
import eye17 from '../img/eye/eye17.svg';
import eye18 from '../img/eye/eye18.svg';
import eye19 from '../img/eye/eye19.svg';
import eye20left from '../img/eye/eye20-left.svg';
import eye20right from '../img/eye/eye20-right.svg';
import eye21 from '../img/eye/eye21.svg';
import eye22 from '../img/eye/eye22.svg';
import eye23left from '../img/eye/eye23-left.svg';
import eye23right from '../img/eye/eye23-right.svg';
import eye24left from '../img/eye/eye24-left.svg';
import eye24right from '../img/eye/eye24-right.svg';
import eye25left from '../img/eye/eye25-left.svg';
import eye25right from '../img/eye/eye25-right.svg';
import eye26left from '../img/eye/eye26-left.svg';
import eye26right from '../img/eye/eye26-right.svg';
import eye27left from '../img/eye/eye27-left.svg';
import eye27right from '../img/eye/eye27-right.svg';
import eye28 from '../img/eye/eye28.svg';
import eye29left from '../img/eye/eye29-left.svg';
import eye29right from '../img/eye/eye29-right.svg';
import eye30 from '../img/eye/eye30.svg';
import eye31 from '../img/eye/eye31.svg';
import mouth1 from '../img/mouth/mouth1.svg';
import mouth2 from '../img/mouth/mouth2.svg';
import mouth3 from '../img/mouth/mouth3.svg';
import mouth4 from '../img/mouth/mouth4.svg';
import mouth5 from '../img/mouth/mouth5.svg';
import mouth6 from '../img/mouth/mouth6.svg';
import mouth7 from '../img/mouth/mouth7.svg';
import mouth8 from '../img/mouth/mouth8.svg';
import mouth9 from '../img/mouth/mouth9.svg';
import mouth10 from '../img/mouth/mouth10.svg';
import mouth11 from '../img/mouth/mouth11.svg';
import mouth12 from '../img/mouth/mouth12.svg';
import mouth13 from '../img/mouth/mouth13.svg';
import mouth14 from '../img/mouth/mouth14.svg';
import mouth15 from '../img/mouth/mouth15.svg';
import mouth16 from '../img/mouth/mouth16.svg';
import mouth17 from '../img/mouth/mouth17.svg';
import mouth18 from '../img/mouth/mouth18.svg';
import mouth19 from '../img/mouth/mouth19.svg';
import mouth20 from '../img/mouth/mouth20.svg';
import mouth21 from '../img/mouth/mouth21.svg';
import mouth22 from '../img/mouth/mouth22.svg';
import mouth23 from '../img/mouth/mouth23.svg';
import mouth24 from '../img/mouth/mouth24.svg';
import mouth25 from '../img/mouth/mouth25.svg';
import mouth26 from '../img/mouth/mouth26.svg';
import mouth27 from '../img/mouth/mouth27.svg';
import mouth28 from '../img/mouth/mouth28.svg';
import mouth29 from '../img/mouth/mouth29.svg';
import mouth30 from '../img/mouth/mouth30.svg';
import mouth31 from '../img/mouth/mouth31.svg';
import mouth32 from '../img/mouth/mouth32.svg';
import mouth33 from '../img/mouth/mouth33.svg';
import mouth34 from '../img/mouth/mouth34.svg';
import mouth35 from '../img/mouth/mouth35.svg';
import mouth36 from '../img/mouth/mouth36.svg';
import mouth37 from '../img/mouth/mouth37.svg';
import mouth38 from '../img/mouth/mouth38.svg';
import mouth39 from '../img/mouth/mouth39.svg';
import mouth40 from '../img/mouth/mouth40.svg';
import mouth41 from '../img/mouth/mouth41.svg';
import accessory1_1 from '../img/accessory/accessory1-1.svg';
import accessory1_2 from '../img/accessory/accessory1-2.svg';
import accessory1_3 from '../img/accessory/accessory1-3.svg';
import accessory2 from '../img/accessory/accessory2.svg';
import accessory4_1 from '../img/accessory/accessory4-1.svg';
import accessory4_2 from '../img/accessory/accessory4-2.svg';
import accessory4_3 from '../img/accessory/accessory4-3.svg';
import accessory4_4 from '../img/accessory/accessory4-4.svg';
import accessory5_1 from '../img/accessory/accessory5-1.svg';
import accessory5_2 from '../img/accessory/accessory5-2.svg';
import accessory6_1 from '../img/accessory/accessory6-1.svg';
import accessory6_2 from '../img/accessory/accessory6-2.svg';
import accessory7_1 from '../img/accessory/accessory7-1.svg';
import accessory7_2 from '../img/accessory/accessory7-2.svg';
import accessory7_3 from '../img/accessory/accessory7-3.svg';
import accessory8_1 from '../img/accessory/accessory8-1.svg';
import accessory8_2 from '../img/accessory/accessory8-2.svg';
import accessory8_3 from '../img/accessory/accessory8-3.svg';
import accessory8_4 from '../img/accessory/accessory8-4.svg';
import accessory9 from '../img/accessory/accessory9.svg';
import accessory10_1 from '../img/accessory/accessory10-1.svg';
import accessory10_2 from '../img/accessory/accessory10-2.svg';
import accessory10_3 from '../img/accessory/accessory10-3.svg';
import accessory10_4 from '../img/accessory/accessory10-4.svg';
import accessory11 from '../img/accessory/accessory11.svg';
import accessory12 from '../img/accessory/accessory12.svg';
import accessory13 from '../img/accessory/accessory13.svg';
import accessory14_1 from '../img/accessory/accessory14-1.svg';
import accessory14_2 from '../img/accessory/accessory14-2.svg';
import accessory16_1 from '../img/accessory/accessory16-1.svg';
import accessory16_2 from '../img/accessory/accessory16-2.svg';
import accessory17 from '../img/accessory/accessory17.svg';
import accessory18_1 from '../img/accessory/accessory18-1.svg';
import accessory18_2 from '../img/accessory/accessory18-2.svg';
import accessory18_3 from '../img/accessory/accessory18-3.svg';
import accessory18_4 from '../img/accessory/accessory18-4.svg';
import accessory18_5 from '../img/accessory/accessory18-5.svg';
import accessory18_6 from '../img/accessory/accessory18-6.svg';
import accessory19_1 from '../img/accessory/accessory19-1.svg';
import accessory19_2 from '../img/accessory/accessory19-2.svg';
import accessory19_3 from '../img/accessory/accessory19-3.svg';

const GetEmojiMenuList = () => {
  let emojiMenuList = {
    "😀":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:132,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:299,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:mouth1,
        name:"mouth1",
        width:305,
        height:152,
        x:97,
        y:277,
        key: 0,
      target: false
      }
    ],
    "😃":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye2,
        name:"eye2",
        width:69,
        height:152,
        x:125,
        y:97,
        key: 0,
      target: false
      },
      {
        pass:eye2,
        name:"eye2",
        width:69,
        height:152,
        x:306,
        y:97,
        key: 0,
      target: false
      },
      {
        pass:mouth1,
        name:"mouth1",
        width:305,
        height:152,
        x:97,
        y:291,
        key: 0,
      target: false
      }
    ],
    "😄":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye3,
        name:"eye3",
        width:125,
        height:56,
        x:104,
        y:201,
        key: 0,
      target: false
      },
      {
        pass:eye3,
        name:"eye3",
        width:125,
        height:56,
        x:271,
        y:201,
        key: 0,
      target: false
      },
      {
        pass:mouth1,
        name:"mouth1",
        width:305,
        height:152,
        x:97,
        y:291,
        key: 0,
      target: false
      }
    ],
    "😁":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye4,
        name:"eye4",
        width:111,
        height:69,
        x:125,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye4,
        name:"eye4",
        width:111,
        height:69,
        x:264,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:mouth2,
        name:"mouth2",
        width:305,
        height:152,
        x:97,
        y:291,
        key: 0,
      target: false
      }
    ],
    "😆":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye5left,
        name:"eye5-left",
        width:153,
        height:111,
        x:70,
        y:167,
        key: 0,
      target: false
      },
      {
        pass:eye5right,
        name:"eye5-right",
        width:153,
        height:111,
        x:278,
        y:167,
        key: 0,
      target: false
      },
      {
        pass:mouth1,
        name:"mouth1",
        width:305,
        height:152,
        x:97,
        y:291,
        key: 0,
      target: false
      }
    ],
    "😅":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye3,
        name:"eye3",
        width:125,
        height:56,
        x:104,
        y:201,
        key: 0,
      target: false
      },
      {
        pass:eye3,
        name:"eye3",
        width:125,
        height:56,
        x:271,
        y:201,
        key: 0,
      target: false
      },
      {
        pass:mouth1,
        name:"mouth1",
        width:305,
        height:152,
        x:97,
        y:291,
        key: 0,
      target: false
      },
      {
        pass:accessory1_1,
        name:"accessory1-1",
        width:139,
        height:208,
        x:347,
        y:14,
        key: 0,
      target: false
      }
    ],
    "🤣":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye6left,
        name:"eye6-left",
        width:136,
        height:146,
        x:42,
        y:209,
        key: 0,
      target: false
      },
      {
        pass:eye6right,
        name:"eye6-right",
        width:146,
        height:136,
        x:209,
        y:42,
        key: 0,
      target: false
      },
      {
        pass:mouth3,
        name:"mouth3",
        width:349,
        height:349,
        x:119,
        y:119,
        key: 0,
      target: false
      },
      {
        pass:accessory1_2,
        name:"accessory1-2",
        width:139,
        height:207,
        x:31,
        y:287,
        key: 0,
      target: false
      },
      {
        pass:accessory1_3,
        name:"accessory1-3",
        width:207,
        height:139,
        x:289,
        y:29,
        key: 0,
      target: false
      }
    ],
    "🙂":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:132,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:299,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:mouth4,
        name:"mouth4",
        width:208,
        height:69,
        x:146,
        y:320,
        key: 0,
      target: false
      }
    ],
    "😉":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8left,
        name:"eyebrow8-left",
        width:125,
        height:84,
        x:70,
        y:90,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8right,
        name:"eyebrow8-right",
        width:139,
        height:71,
        x:277,
        y:132,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye3,
        name:"eye3",
        width:125,
        height:56,
        x:271,
        y:201,
        key: 0,
      target: false
      },
      {
        pass:mouth5,
        name:"mouth5",
        width:153,
        height:69,
        x:174,
        y:326,
        key: 0,
      target: false
      }
    ],
    "😊":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:accessory2,
        name:"accessory2",
        width:140,
        height:140,
        x:28,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:accessory2,
        name:"accessory2",
        width:140,
        height:140,
        x:333,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye7,
        name:"eye7",
        width:139,
        height:56,
        x:97,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:eye7,
        name:"eye7",
        width:139,
        height:56,
        x:264,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:mouth6,
        name:"mouth6",
        width:264,
        height:84,
        x:118,
        y:299,
        key: 0,
      target: false
      },
    ],
    "😇":[
      {
        pass:base9,
        name:"base9",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye4,
        name:"eye4",
        width:112,
        height:69,
        x:125,
        y:194,
        key: 0,
      target: false
      },
      {
        pass:eye4,
        name:"eye4",
        width:112,
        height:69,
        x:264,
        y:194,
        key: 0,
      target: false
      },
      {
        pass:mouth6,
        name:"mouth6",
        width:264,
        height:83,
        x:118,
        y:313,
        key: 0,
      target: false
      },
    ],
    "🥰":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye4,
        name:"eye4",
        width:112,
        height:69,
        x:125,
        y:194,
        key: 0,
      target: false
      },
      {
        pass:eye4,
        name:"eye4",
        width:112,
        height:69,
        x:264,
        y:194,
        key: 0,
      target: false
      },
      {
        pass:mouth6,
        name:"mouth6",
        width:264,
        height:83,
        x:118,
        y:286,
        key: 0,
      target: false
      },
      {
        pass:accessory4_1,
        name:"accessory4-1",
        width:172,
        height:148,
        x:295,
        y:3,
        key: 0,
      target: false
      },
      {
        pass:accessory4_2,
        name:"accessory4-2",
        width:191,
        height:150,
        x:3,
        y:347,
        key: 0,
      target: false
      },
      {
        pass:accessory4_3,
        name:"accessory4-3",
        width:137,
        height:105,
        x:362,
        y:331,
        key: 0,
      target: false
      }
    ],
    "😍":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:accessory5_1,
        name:"accessory5-1",
        width:231,
        height:221,
        x:6,
        y:2,
        key: 0,
      target: false
      },
      {
        pass:accessory5_2,
        name:"accessory5-2",
        width:230,
        height:221,
        x:264,
        y:2,
        key: 0,
      target: false
      },
      {
        pass:mouth7,
        name:"mouth7",
        width:250,
        height:126,
        x:125,
        y:292,
        key: 0,
      target: false
      },
    ],
    "🤩":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:accessory6_1,
        name:"accessory6-1",
        width:236,
        height:239,
        x:2,
        y:4,
        key: 0,
      target: false
      },
      {
        pass:accessory6_2,
        name:"accessory6-2",
        width:236,
        height:239,
        x:262,
        y:4,
        key: 0,
      target: false
      },
      {
        pass:mouth1,
        name:"mouth1",
        width:306,
        height:153,
        x:97,
        y:277,
        key: 0,
      target: false
      },
    ],
    "😘":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8left,
        name:"eyebrow8-left",
        width:125,
        height:84,
        x:70,
        y:69,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8right,
        name:"eyebrow8-right",
        width:139,
        height:71,
        x:278,
        y:123,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:167,
        key: 0,
      target: false
      },
      {
        pass:eye3,
        name:"eye3",
        width:125,
        height:56,
        x:271,
        y:201,
        key: 0,
      target: false
      },
      {
        pass:mouth8,
        name:"mouth8",
        width:83,
        height:153,
        x:232,
        y:299,
        key: 0,
      target: false
      },
      {
        pass:accessory4_4,
        name:"accessory4-4",
        width:171,
        height:155,
        x:328,
        y:287,
        key: 0,
      target: false
      },
    ],
    "😗":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:139,
        y:167,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:292,
        y:167,
        key: 0,
      target: false
      },
      {
        pass:mouth8,
        name:"mouth8",
        width:83,
        height:153,
        x:243,
        y:312,
        key: 0,
      target: false
      },
    ],
    "☺️":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:accessory2,
        name:"accessory2",
        width:140,
        height:140,
        x:28,
        y:236,
        key: 0,
      target: false
      },
      {
        pass:accessory2,
        name:"accessory2",
        width:140,
        height:140,
        x:333,
        y:236,
        key: 0,
      target: false
      },
      {
        pass:eyebrow1left,
        name:"eyebrow1-left",
        width:139,
        height:58,
        x:83,
        y:137,
        key: 0,
      target: false
      },
      {
        pass:eyebrow1right,
        name:"eyebrow1-right",
        width:139,
        height:58,
        x:278,
        y:137,
        key: 0,
      target: false
      },
      {
        pass:eye9left,
        name:"eye9-left",
        width:111,
        height:45,
        x:125,
        y:219,
        key: 0,
      target: false
      },
      {
        pass:eye9right,
        name:"eye9-right",
        width:111,
        height:45,
        x:264,
        y:219,
        key: 0,
      target: false
      },
      {
        pass:mouth5,
        name:"mouth5",
        width:153,
        height:69,
        x:174,
        y:340,
        key: 0,
      target: false
      },
    ],
    "😚":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:accessory2,
        name:"accessory2",
        width:140,
        height:140,
        x:28,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:accessory2,
        name:"accessory2",
        width:140,
        height:140,
        x:333,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8left,
        name:"eyebrow8-left",
        width:125,
        height:84,
        x:56,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8right,
        name:"eyebrow8-right",
        width:125,
        height:84,
        x:320,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:eye10,
        name:"eye10",
        width:125,
        height:42,
        x:104,
        y:215,
        key: 0,
      target: false
      },
      {
        pass:eye10,
        name:"eye10",
        width:125,
        height:42,
        x:271,
        y:215,
        key: 0,
      target: false
      },
      {
        pass:mouth8,
        name:"mouth8",
        width:84,
        height:152,
        x:257,
        y:299,
        key: 0,
      target: false
      },
    ],
    "😙":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye4,
        name:"eye4",
        width:112,
        height:69,
        x:125,
        y:194,
        key: 0,
      target: false
      },
      {
        pass:eye4,
        name:"eye4",
        width:112,
        height:69,
        x:264,
        y:194,
        key: 0,
      target: false
      },
      {
        pass:mouth8,
        name:"mouth8",
        width:83,
        height:153,
        x:243,
        y:312,
        key: 0,
      target: false
      },
    ],
    "😋":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye7,
        name:"eye7",
        width:140,
        height:56,
        x:83,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye7,
        name:"eye7",
        width:140,
        height:56,
        x:278,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:mouth9,
        name:"mouth9",
        width:333,
        height:139,
        x:83,
        y:292,
        key: 0,
      target: false
      },
      {
        pass:accessory7_2,
        name:"accessory7-2",
        width:176,
        height:156,
        x:293,
        y:327,
        key: 0,
      target: false
      },
    ],
    "😛":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:306,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:mouth10,
        name:"mouth10",
        width:306,
        height:125,
        x:97,
        y:295,
        key: 0,
      target: false
      },
      {
        pass:accessory7_1,
        name:"accessory7-1",
        width:167,
        height:180,
        x:167,
        y:320,
        key: 0,
      target: false
      },
    ],
    "😜":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye3,
        name:"eye3",
        width:125,
        height:56,
        x:90,
        y:174,
        key: 0,
      target: false
      },
      {
        pass:eye8,
        name:"eye8",
        width:181,
        height:181,
        x:250,
        y:97,
        key: 0,
      target: false
      },
      {
        pass:mouth10,
        name:"mouth10",
        width:306,
        height:125,
        x:97,
        y:295,
        key: 0,
      target: false
      },
      {
        pass:accessory7_1,
        name:"accessory7-1",
        width:167,
        height:180,
        x:167,
        y:320,
        key: 0,
      target: false
      },
    ],
    "😝":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye5left,
        name:"eye5-left",
        width:153,
        height:111,
        x:70,
        y:167,
        key: 0,
      target: false
      },
      {
        pass:eye5right,
        name:"eye5-right",
        width:153,
        height:111,
        x:278,
        y:167,
        key: 0,
      target: false
      },
      {
        pass:mouth10,
        name:"mouth10",
        width:306,
        height:125,
        x:97,
        y:295,
        key: 0,
      target: false
      },
      {
        pass:accessory7_1,
        name:"accessory7-1",
        width:167,
        height:180,
        x:167,
        y:320,
        key: 0,
      target: false
      },
    ],
    "🤗":[
      {
        pass:base1,
        name:"base1",
        width:445,
        height:445,
        x:28,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye11,
        name:"eye11",
        width:107,
        height:50,
        x:125,
        y:111,
        key: 0,
      target: false
      },
      {
        pass:eye11,
        name:"eye11",
        width:107,
        height:50,
        x:278,
        y:111,
        key: 0,
      target: false
      },
      {
        pass:mouth11,
        name:"mouth11",
        width:222,
        height:83,
        x:139,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:accessory8_1,
        name:"accessory8-1",
        width:234,
        height:223,
        x:3,
        y:277,
        key: 0,
      target: false
      },
      {
        pass:accessory8_2,
        name:"accessory8-2",
        width:234,
        height:223,
        x:264,
        y:277,
        key: 0,
      target: false
      },
    ],
    "🤭":[
      {
        pass:base1,
        name:"base1",
        width:472,
        height:472,
        x:14,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye3,
        name:"eye3",
        width:125,
        height:56,
        x:104,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:eye3,
        name:"eye3",
        width:125,
        height:56,
        x:271,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:accessory8_3,
        name:"accessory8-3",
        width:277,
        height:273,
        x:98,
        y:227,
        key: 0,
      target: false
      },
    ],
    "🤔":[
      {
        pass:base1,
        name:"base1",
        width:472,
        height:472,
        x:14,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow9left,
        name:"eyebrow9-left",
        width:122,
        height:34,
        x:122,
        y:56,
        key: 0,
      target: false
      },
      {
        pass:eyebrow10right,
        name:"eyebrow10-right",
        width:122,
        height:38,
        x:292,
        y:111,
        key: 0,
      target: false
      },
      {
        pass:eye12,
        name:"eye12",
        width:59,
        height:74,
        x:153,
        y:118,
        key: 0,
      target: false
      },
      {
        pass:eye12,
        name:"eye12",
        width:59,
        height:74,
        x:309,
        y:133,
        key: 0,
      target: false
      },
      {
        pass:mouth12,
        name:"mouth12",
        width:121,
        height:45,
        x:197,
        y:268,
        key: 0,
      target: false
      },
      {
        pass:accessory8_4,
        name:"accessory8-4",
        width:266,
        height:252,
        x:82,
        y:248,
        key: 0,
      target: false
      },
    ],
    "🤨":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow11left,
        name:"eyebrow11-left",
        width:137,
        height:46,
        x:93,
        y:141,
        key: 0,
      target: false
      },
      {
        pass:eyebrow12right,
        name:"eyebrow12-right",
        width:170,
        height:86,
        x:264,
        y:58,
        key: 0,
      target: false
      },
      {
        pass:eye13,
        name:"eye13",
        width:69,
        height:76,
        x:132,
        y:191,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:299,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:mouth13,
        name:"mouth13",
        width:222,
        height:28,
        x:139,
        y:333,
        key: 0,
      target: false
      },
    ],
    "😐️":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:306,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:mouth13,
        name:"mouth13",
        width:222,
        height:28,
        x:139,
        y:333,
        key: 0,
      target: false
      },
    ],
    "😑":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye14,
        name:"eye14",
        width:125,
        height:28,
        x:97,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:eye14,
        name:"eye14",
        width:125,
        height:28,
        x:278,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:mouth13,
        name:"mouth13",
        width:222,
        height:28,
        x:139,
        y:333,
        key: 0,
      target: false
      },
    ],
    "😶":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:306,
        y:181,
        key: 0,
      target: false
      },
    ],
    "😒":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow2left,
        name:"eyebrow2-left",
        width:139,
        height:57,
        x:83,
        y:138,
        key: 0,
      target: false
      },
      {
        pass:eyebrow2right,
        name:"eyebrow2-right",
        width:139,
        height:57,
        x:278,
        y:138,
        key: 0,
      target: false
      },
      {
        pass:eye15,
        name:"eye15",
        width:125,
        height:42,
        x:97,
        y:208,
        key: 0,
      target: false
      },
      {
        pass:eye15,
        name:"eye15",
        width:125,
        height:42,
        x:278,
        y:208,
        key: 0,
      target: false
      },
      {
        pass:mouth14,
        name:"mouth14",
        width:208,
        height:69,
        x:146,
        y:326,
        key: 0,
      target: false
      },
    ],
    "🙄":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye16,
        name:"eye16",
        width:152,
        height:152,
        x:83,
        y:111,
        key: 0,
      target: false
      },
      {
        pass:eye16,
        name:"eye16",
        width:152,
        height:152,
        x:264,
        y:111,
        key: 0,
      target: false
      },
      {
        pass:mouth15,
        name:"mouth15",
        width:183,
        height:72,
        x:165,
        y:322,
        key: 0,
      target: false
      },
    ],
    "😬":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:132,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:299,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:mouth16,
        name:"mouth16",
        width:333,
        height:139,
        x:83,
        y:278,
        key: 0,
      target: false
      },
    ],
    "😌":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow3left,
        name:"eyebrow3-left",
        width:140,
        height:71,
        x:83,
        y:110,
        key: 0,
      target: false
      },
      {
        pass:eyebrow3right,
        name:"eyebrow3-right",
        width:139,
        height:71,
        x:278,
        y:110,
        key: 0,
      target: false
      },
      {
        pass:eye17,
        name:"eye17",
        width:125,
        height:56,
        x:104,
        y:215,
        key: 0,
      target: false
      },
      {
        pass:eye17,
        name:"eye17",
        width:125,
        height:56,
        x:271,
        y:215,
        key: 0,
      target: false
      },
      {
        pass:mouth5,
        name:"mouth5",
        width:153,
        height:69,
        x:174,
        y:326,
        key: 0,
      target: false
      },
    ],
    "😔":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4left,
        name:"eyebrow4-left",
        width:125,
        height:83,
        x:69,
        y:111,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4right,
        name:"eyebrow4-right",
        width:125,
        height:83,
        x:306,
        y:111,
        key: 0,
      target: false
      },
      {
        pass:eye17,
        name:"eye17",
        width:125,
        height:56,
        x:118,
        y:243,
        key: 0,
      target: false
      },
      {
        pass:eye17,
        name:"eye17",
        width:125,
        height:56,
        x:257,
        y:243,
        key: 0,
      target: false
      },
      {
        pass:mouth17,
        name:"mouth17",
        width:139,
        height:28,
        x:181,
        y:361,
        key: 0,
      target: false
      },
    ],
    "😪":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8left,
        name:"eyebrow8-left",
        width:125,
        height:84,
        x:56,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8right,
        name:"eyebrow8-right",
        width:125,
        height:84,
        x:320,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:eye18,
        name:"eye18",
        width:125,
        height:42,
        x:104,
        y:243,
        key: 0,
      target: false
      },
      {
        pass:eye18,
        name:"eye18",
        width:125,
        height:42,
        x:271,
        y:243,
        key: 0,
      target: false
      },
      {
        pass:mouth18,
        name:"mouth18",
        width:153,
        height:69,
        x:174,
        y:340,
        key: 0,
      target: false
      },
      {
        pass:accessory9,
        name:"accessory9",
        width:181,
        height:181,
        x:320,
        y:320,
        key: 0,
      target: false
      },
    ],
    "🤢":[
      {
        pass:base4,
        name:"base4",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye29left,
        name:"eye29-left",
        width:101,
        height:68,
        x:109,
        y:160,
        key: 0,
      target: false
      },
      {
        pass:eye29right,
        name:"eye29-right",
        width:101,
        height:68,
        x:289,
        y:160,
        key: 0,
      target: false
      },
      {
        pass:mouth37,
        name:"mouth37",
        width:206,
        height:147,
        x:147,
        y:265,
        key: 0,
      target: false
      },
    ],
    "🥵":[
      {
        pass:base5,
        name:"base5",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow15left,
        name:"eyebrow15-left",
        width:125,
        height:83,
        x:83,
        y:83,
        key: 0,
      target: false
      },
      {
        pass:eyebrow15right,
        name:"eyebrow15-right",
        width:125,
        height:83,
        x:292,
        y:83,
        key: 0,
      target: false
      },
      {
        pass:eye30,
        name:"eye30",
        width:69,
        height:97,
        x:132,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye30,
        name:"eye30",
        width:69,
        height:97,
        x:299,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:mouth38,
        name:"mouth38",
        width:236,
        height:101,
        x:132,
        y:306,
        key: 0,
      target: false
      },
      {
        pass:accessory7_3,
        name:"accessory7-3",
        width:167,
        height:122,
        x:168,
        y:320,
        key: 0,
      target: false
      },
      {
        pass:accessory1_1,
        name:"accessory1-1",
        width:116,
        height:175,
        x:3,
        y:1,
        key: 0,
      target: false
      },
      {
        pass:accessory1_1,
        name:"accessory1-1",
        width:142,
        height:212,
        x:357,
        y:26,
        key: 0,
      target: false
      },
    ],
    "🥶":[
      {
        pass:base3,
        name:"base3",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow16left,
        name:"eyebrow16-left",
        width:125,
        height:84,
        x:69,
        y:75,
        key: 0,
      target: false
      },
      {
        pass:eyebrow16right,
        name:"eyebrow16-right",
        width:125,
        height:83,
        x:305,
        y:75,
        key: 0,
      target: false
      },
      {
        pass:eye31,
        name:"eye31",
        width:69,
        height:97,
        x:132,
        y:162,
        key: 0,
      target: false
      },
      {
        pass:eye31,
        name:"eye31",
        width:69,
        height:97,
        x:298,
        y:162,
        key: 0,
      target: false
      },
      {
        pass:mouth39,
        name:"mouth39",
        width:333,
        height:139,
        x:83,
        y:289,
        key: 0,
      target: false
      },
      {
        pass:accessory10_1,
        name:"accessory10-1",
        width:59,
        height:163,
        x:82,
        y:109,
        key: 0,
      target: false
      },
      {
        pass:accessory10_2,
        name:"accessory10-2",
        width:61,
        height:157,
        x:276,
        y:2,
        key: 0,
      target: false
      },
      {
        pass:accessory10_3,
        name:"accessory10-3",
        width:55,
        height:201,
        x:4,
        y:296,
        key: 0,
      target: false
      },
      {
        pass:accessory10_4,
        name:"accessory10-4",
        width:73,
        height:270,
        x:427,
        y:210,
        key: 0,
      target: false
      },
    ],
    "🥴":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4left,
        name:"eyebrow4-left",
        width:125,
        height:83,
        x:69,
        y:69,
        key: 0,
      target: false
      },
      {
        pass:eyebrow13right,
        name:"eyebrow13-right",
        width:104,
        height:110,
        x:326,
        y:94,
        key: 0,
      target: false
      },
      {
        pass:eye20left,
        name:"eye20-left",
        width:75,
        height:99,
        x:135,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:eye20right,
        name:"eye20-right",
        width:86,
        height:115,
        x:280,
        y:161,
        key: 0,
      target: false
      },
      {
        pass:mouth19,
        name:"mouth19",
        width:289,
        height:115,
        x:113,
        y:295,
        key: 0,
      target: false
      },
    ],
    "😵":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8left,
        name:"eyebrow8-left",
        width:125,
        height:83,
        x:70,
        y:69,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8right,
        name:"eyebrow8-right",
        width:125,
        height:83,
        x:306,
        y:69,
        key: 0,
      target: false
      },
      {
        pass:eye19,
        name:"eye19",
        width:97,
        height:97,
        x:125,
        y:167,
        key: 0,
      target: false
      },
      {
        pass:eye19,
        name:"eye19",
        width:97,
        height:97,
        x:278,
        y:167,
        key: 0,
      target: false
      },
      {
        pass:mouth20,
        name:"mouth20",
        width:139,
        height:167,
        x:181,
        y:292,
        key: 0,
      target: false
      },
    ],
    "🤯":[
      {
        pass:base8,
        name:"base8",
        width:472,
        height:498,
        x:14,
        y:2,
        key: 0,
      target: false
      },
      {
        pass:eye21,
        name:"eye21",
        width:56,
        height:74,
        x:139,
        y:249,
        key: 0,
      target: false
      },
      {
        pass:eye21,
        name:"eye21",
        width:56,
        height:74,
        x:306,
        y:249,
        key: 0,
      target: false
      },
      {
        pass:mouth21,
        name:"mouth21",
        width:120,
        height:138,
        x:190,
        y:329,
        key: 0,
      target: false
      },
    ],
    "🤠":[
      {
        pass:base1,
        name:"base1",
        width:417,
        height:417,
        x:42,
        y:83,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:57,
        height:80,
        x:147,
        y:235,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:57,
        height:80,
        x:296,
        y:235,
        key: 0,
      target: false
      },
      {
        pass:mouth6,
        name:"mouth6",
        width:217,
        height:69,
        x:141,
        y:354,
        key: 0,
      target: false
      },
      {
        pass:accessory11,
        name:"accessory11",
        width:498,
        height:208,
        x:1,
        y:14,
        key: 0,
      target: false
      },
    ],
    "🥳":[
      {
        pass:base1,
        name:"base1",
        width:472,
        height:472,
        x:0,
        y:28,
        key: 0,
      target: false
      },
      {
        pass:eye23left,
        name:"eye23-left",
        width:120,
        height:66,
        x:99,
        y:232,
        key: 0,
      target: false
      },
      {
        pass:eye23right,
        name:"eye23-right",
        width:126,
        height:48,
        x:265,
        y:198,
        key: 0,
      target: false
      },
      {
        pass:mouth22,
        name:"mouth22",
        width:220,
        height:151,
        x:132,
        y:284,
        key: 0,
      target: false
      },
      {
        pass:accessory18_1,
        name:"accessory18-1",
        width:238,
        height:239,
        x:2,
        y:2,
        key: 0,
      target: false
      },
      {
        pass:accessory18_4,
        name:"accessory18-4",
        width:56,
        height:56,
        x:306,
        y:28,
        key: 0,
      target: false
      },
      {
        pass:accessory18_3,
        name:"accessory18-3",
        width:56,
        height:56,
        x:375,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:accessory18_6,
        name:"accessory18-6",
        width:56,
        height:97,
        x:444,
        y:83,
        key: 0,
      target: false
      },
      {
        pass:accessory18_5,
        name:"accessory18-5",
        width:62,
        height:84,
        x:6,
        y:328,
        key: 0,
      target: false
      },
      {
        pass:accessory18_3,
        name:"accessory18-3",
        width:41,
        height:41,
        x:14,
        y:445,
        key: 0,
      target: false
      },
      {
        pass:accessory18_2,
        name:"accessory18-2",
        width:274,
        height:129,
        x:227,
        y:283,
        key: 0,
      target: false
      },
    ],
    "😎":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:mouth6,
        name:"mouth6",
        width:264,
        height:83,
        x:118,
        y:326,
        key: 0,
      target: false
      },
      {
        pass:accessory12,
        name:"accessory12",
        width:471,
        height:145,
        x:15,
        y:125,
        key: 0,
      target: false
      },
    ],
    "🧐":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow14left,
        name:"eyebrow14-left",
        width:132,
        height:64,
        x:88,
        y:74,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:74,
        height:103,
        x:132,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:mouth23,
        name:"mouth23",
        width:118,
        height:41,
        x:191,
        y:357,
        key: 0,
      target: false
      },
      {
        pass:accessory13,
        name:"accessory13",
        width:250,
        height:412,
        x:235,
        y:88,
        key: 0,
      target: false
      },
    ],
    "😕":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:306,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:mouth24,
        name:"mouth24",
        width:181,
        height:59,
        x:167,
        y:330,
        key: 0,
      target: false
      },
    ],
    "😟":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4left,
        name:"eyebrow4-left",
        width:125,
        height:83,
        x:83,
        y:83,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4right,
        name:"eyebrow4-right",
        width:125,
        height:83,
        x:292,
        y:83,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:132,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:299,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:mouth18,
        name:"mouth18",
        width:153,
        height:69,
        x:174,
        y:340,
        key: 0,
      target: false
      },
    ],
    "🙁":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:132,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:299,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:mouth14,
        name:"mouth14",
        width:208,
        height:69,
        x:146,
        y:320,
        key: 0,
      target: false
      },
    ],
    "☹️":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:306,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:mouth25,
        name:"mouth25",
        width:264,
        height:83,
        x:118,
        y:306,
        key: 0,
      target: false
      },
    ],
    "😮":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:132,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:299,
        y:139,
        key: 0,
      target: false
      },
      {
        pass:mouth26,
        name:"mouth26",
        width:111,
        height:139,
        x:194,
        y:278,
        key: 0,
      target: false
      },
    ],
    "😯":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8left,
        name:"eyebrow8-left",
        width:125,
        height:84,
        x:56,
        y:70,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8right,
        name:"eyebrow8-right",
        width:125,
        height:84,
        x:320,
        y:70,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:132,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:299,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:mouth27,
        name:"mouth27",
        width:83,
        height:97,
        x:208,
        y:306,
        key: 0,
      target: false
      },
    ],
    "😲":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8left,
        name:"eyebrow8-left",
        width:125,
        height:84,
        x:70,
        y:69,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8right,
        name:"eyebrow8-right",
        width:125,
        height:84,
        x:306,
        y:69,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:132,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:299,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:mouth28,
        name:"mouth28",
        width:139,
        height:167,
        x:181,
        y:292,
        key: 0,
      target: false
      },
    ],
    "😳":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:accessory2,
        name:"accessory2",
        width:139,
        height:139,
        x:28,
        y:250,
        key: 0,
      target: false
      },
      {
        pass:accessory2,
        name:"accessory2",
        width:139,
        height:139,
        x:333,
        y:250,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8left,
        name:"eyebrow8-left",
        width:125,
        height:84,
        x:70,
        y:69,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8right,
        name:"eyebrow8-right",
        width:125,
        height:84,
        x:306,
        y:69,
        key: 0,
      target: false
      },
      {
        pass:eye8,
        name:"eye8",
        width:153,
        height:153,
        x:83,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:eye8,
        name:"eye8",
        width:153,
        height:153,
        x:264,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:mouth17,
        name:"mouth17",
        width:139,
        height:28,
        x:181,
        y:389,
        key: 0,
      target: false
      },
    ],
    "🥺":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye24left,
        name:"eye24-left",
        width:173,
        height:183,
        x:57,
        y:103,
        key: 0,
      target: false
      },
      {
        pass:eye24right,
        name:"eye24-right",
        width:174,
        height:184,
        x:269,
        y:103,
        key: 0,
      target: false
      },
      {
        pass:mouth23,
        name:"mouth23",
        width:111,
        height:34,
        x:195,
        y:341,
        key: 0,
      target: false
      },
    ],
    "😦":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:306,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:mouth18,
        name:"mouth18",
        width:153,
        height:69,
        x:174,
        y:326,
        key: 0,
      target: false
      },
    ],
    "😧":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8left,
        name:"eyebrow8-left",
        width:125,
        height:84,
        x:70,
        y:83,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8right,
        name:"eyebrow8-right",
        width:125,
        height:84,
        x:306,
        y:83,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:306,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:mouth18,
        name:"mouth18",
        width:153,
        height:69,
        x:174,
        y:326,
        key: 0,
      target: false
      },
    ],
    "😨":[
      {
        pass:base7,
        name:"base7",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8left,
        name:"eyebrow8-left",
        width:125,
        height:84,
        x:70,
        y:83,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8right,
        name:"eyebrow8-right",
        width:125,
        height:84,
        x:306,
        y:83,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:306,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:mouth18,
        name:"mouth18",
        width:153,
        height:69,
        x:174,
        y:326,
        key: 0,
      target: false
      },
    ],
    "😰":[
      {
        pass:base7,
        name:"base7",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4left,
        name:"eyebrow4-left",
        width:125,
        height:83,
        x:69,
        y:83,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4right,
        name:"eyebrow4-right",
        width:125,
        height:83,
        x:306,
        y:83,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:306,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:mouth18,
        name:"mouth18",
        width:153,
        height:69,
        x:174,
        y:326,
        key: 0,
      target: false
      },
      {
        pass:accessory1_1,
        name:"accessory1-1",
        width:139,
        height:208,
        x:0,
        y:278,
        key: 0,
      target: false
      },
    ],
    "😥":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4left,
        name:"eyebrow4-left",
        width:125,
        height:83,
        x:70,
        y:97,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4right,
        name:"eyebrow4-right",
        width:125,
        height:83,
        x:306,
        y:97,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:306,
        y:181,
        key: 0,
      target: false
      },
      {
        pass:mouth18,
        name:"mouth18",
        width:153,
        height:69,
        x:174,
        y:326,
        key: 0,
      target: false
      },
      {
        pass:accessory1_1,
        name:"accessory1-1",
        width:139,
        height:208,
        x:0,
        y:278,
        key: 0,
      target: false
      },
    ],
    "😢":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8left,
        name:"eyebrow8-left",
        width:125,
        height:84,
        x:69,
        y:104,
        key: 0,
      target: false
      },
      {
        pass:eyebrow8right,
        name:"eyebrow8-right",
        width:125,
        height:84,
        x:306,
        y:104,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:188,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:306,
        y:188,
        key: 0,
      target: false
      },
      {
        pass:mouth18,
        name:"mouth18",
        width:153,
        height:69,
        x:174,
        y:333,
        key: 0,
      target: false
      },
      {
        pass:accessory1_1,
        name:"accessory1-1",
        width:139,
        height:208,
        x:83,
        y:292,
        key: 0,
      target: false
      },
    ],
    "😭":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4left,
        name:"eyebrow4-left",
        width:125,
        height:97,
        x:69,
        y:111,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4right,
        name:"eyebrow4-right",
        width:125,
        height:97,
        x:306,
        y:111,
        key: 0,
      target: false
      },
      {
        pass:mouth29,
        name:"mouth29",
        width:111,
        height:125,
        x:195,
        y:292,
        key: 0,
      target: false
      },
      {
        pass:accessory14_1,
        name:"accessory14-1",
        width:56,
        height:264,
        x:111,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:accessory14_1,
        name:"accessory14-1",
        width:56,
        height:264,
        x:334,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:accessory14_2,
        name:"accessory14-2",
        width:500,
        height:56,
        x:0,
        y:445,
        key: 0,
      target: false
      },
      {
        pass:eye22,
        name:"eye22",
        width:139,
        height:40,
        x:83,
        y:210,
        key: 0,
      target: false
      },
      {
        pass:eye22,
        name:"eye22",
        width:139,
        height:40,
        x:278,
        y:210,
        key: 0,
      target: false
      },
    ],
    "😱":[
      {
        pass:base11,
        name:"base11",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye25left,
        name:"eye25-left",
        width:123,
        height:163,
        x:97,
        y:101,
        key: 0,
      target: false
      },
      {
        pass:eye25right,
        name:"eye25-right",
        width:123,
        height:163,
        x:280,
        y:101,
        key: 0,
      target: false
      },
      {
        pass:mouth30,
        name:"mouth30",
        width:139,
        height:222,
        x:181,
        y:250,
        key: 0,
      target: false
      },
    ],
    "😖":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4left,
        name:"eyebrow4-left",
        width:125,
        height:84,
        x:69,
        y:97,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4right,
        name:"eyebrow4-right",
        width:125,
        height:84,
        x:306,
        y:97,
        key: 0,
      target: false
      },
      {
        pass:eye5left,
        name:"eye5-left",
        width:153,
        height:111,
        x:69,
        y:195,
        key: 0,
      target: false
      },
      {
        pass:eye5right,
        name:"eye5-right",
        width:153,
        height:111,
        x:278,
        y:195,
        key: 0,
      target: false
      },
      {
        pass:mouth31,
        name:"mouth31",
        width:250,
        height:97,
        x:125,
        y:319,
        key: 0,
      target: false
      },
    ],
    "😣":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4left,
        name:"eyebrow4-left",
        width:125,
        height:83,
        x:83,
        y:56,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4right,
        name:"eyebrow4-right",
        width:125,
        height:83,
        x:292,
        y:56,
        key: 0,
      target: false
      },
      {
        pass:eye5left,
        name:"eye5-left",
        width:153,
        height:111,
        x:69,
        y:208,
        key: 0,
      target: false
      },
      {
        pass:eye5right,
        name:"eye5-right",
        width:153,
        height:111,
        x:278,
        y:208,
        key: 0,
      target: false
      },
      {
        pass:mouth32,
        name:"mouth32",
        width:208,
        height:97,
        x:146,
        y:340,
        key: 0,
      target: false
      },
    ],
    "😞":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye26left,
        name:"eye26-left",
        width:122,
        height:69,
        x:94,
        y:216,
        key: 0,
      target: false
      },
      {
        pass:eye26right,
        name:"eye26-right",
        width:122,
        height:69,
        x:284,
        y:216,
        key: 0,
      target: false
      },
      {
        pass:mouth18,
        name:"mouth18",
        width:153,
        height:69,
        x:174,
        y:340,
        key: 0,
      target: false
      },
    ],
    "😓":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye3,
        name:"eye3",
        width:125,
        height:56,
        x:118,
        y:201,
        key: 0,
      target: false
      },
      {
        pass:eye3,
        name:"eye3",
        width:125,
        height:56,
        x:271,
        y:201,
        key: 0,
      target: false
      },
      {
        pass:mouth33,
        name:"mouth33",
        width:139,
        height:56,
        x:181,
        y:333,
        key: 0,
      target: false
      },
      {
        pass:accessory1_1,
        name:"accessory1-1",
        width:139,
        height:208,
        x:14,
        y:14,
        key: 0,
      target: false
      },
    ],
    "😩":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow5left,
        name:"eyebrow5-left",
        width:112,
        height:97,
        x:125,
        y:42,
        key: 0,
      target: false
      },
      {
        pass:eyebrow5right,
        name:"eyebrow5-right",
        width:111,
        height:97,
        x:264,
        y:42,
        key: 0,
      target: false
      },
      {
        pass:eye27left,
        name:"eye27-left",
        width:121,
        height:70,
        x:115,
        y:146,
        key: 0,
      target: false
      },
      {
        pass:eye27right,
        name:"eye27-right",
        width:122,
        height:69,
        x:264,
        y:147,
        key: 0,
      target: false
      },
      {
        pass:mouth34,
        name:"mouth34",
        width:306,
        height:153,
        x:97,
        y:263,
        key: 0,
      target: false
      },
    ],
    "😫":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4left,
        name:"eyebrow4-left",
        width:125,
        height:83,
        x:83,
        y:56,
        key: 0,
      target: false
      },
      {
        pass:eyebrow4right,
        name:"eyebrow4-right",
        width:125,
        height:83,
        x:292,
        y:56,
        key: 0,
      target: false
      },
      {
        pass:eye5left,
        name:"eye5-left",
        width:153,
        height:111,
        x:70,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:eye5right,
        name:"eye5-right",
        width:153,
        height:111,
        x:278,
        y:153,
        key: 0,
      target: false
      },
      {
        pass:mouth35,
        name:"mouth35",
        width:292,
        height:125,
        x:104,
        y:299,
        key: 0,
      target: false
      },
    ],
    "😤":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow6left,
        name:"eyebrow6-left",
        width:125,
        height:112,
        x:111,
        y:70,
        key: 0,
      target: false
      },
      {
        pass:eyebrow6right,
        name:"eyebrow6-right",
        width:125,
        height:112,
        x:264,
        y:70,
        key: 0,
      target: false
      },
      {
        pass:eye28,
        name:"eye28",
        width:125,
        height:40,
        x:97,
        y:208,
        key: 0,
      target: false
      },
      {
        pass:eye28,
        name:"eye28",
        width:125,
        height:40,
        x:278,
        y:208,
        key: 0,
      target: false
      },
      {
        pass:mouth36,
        name:"mouth36",
        width:250,
        height:83,
        x:125,
        y:292,
        key: 0,
      target: false
      },
      {
        pass:accessory16_1,
        name:"accessory16-1",
        width:194,
        height:222,
        x:28,
        y:278,
        key: 0,
      target: false
      },
      {
        pass:accessory16_2,
        name:"accessory16-2",
        width:194,
        height:222,
        x:278,
        y:278,
        key: 0,
      target: false
      },
    ],
    "😡":[
      {
        pass:base2,
        name:"base2",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow17left,
        name:"eyebrow17-left",
        width:140,
        height:69,
        x:83,
        y:195,
        key: 0,
      target: false
      },
      {
        pass:eyebrow17right,
        name:"eyebrow17-right",
        width:140,
        height:69,
        x:278,
        y:195,
        key: 0,
      target: false
      },
      {
        pass:eye30,
        name:"eye30",
        width:69,
        height:97,
        x:139,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:eye30,
        name:"eye30",
        width:69,
        height:97,
        x:292,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:mouth40,
        name:"mouth40",
        width:208,
        height:69,
        x:146,
        y:354,
        key: 0,
      target: false
      },
    ],
    "😠":[
      {
        pass:base1,
        name:"base1",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow7left,
        name:"eyebrow7-left",
        width:140,
        height:69,
        x:83,
        y:195,
        key: 0,
      target: false
      },
      {
        pass:eyebrow7right,
        name:"eyebrow7-right",
        width:140,
        height:69,
        x:278,
        y:195,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:139,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:292,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:mouth14,
        name:"mouth14",
        width:208,
        height:69,
        x:146,
        y:354,
        key: 0,
      target: false
      },
    ],
    "🤬":[
      {
        pass:base2,
        name:"base2",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eyebrow17left,
        name:"eyebrow17-left",
        width:140,
        height:69,
        x:83,
        y:195,
        key: 0,
      target: false
      },
      {
        pass:eyebrow17right,
        name:"eyebrow17-right",
        width:140,
        height:69,
        x:278,
        y:195,
        key: 0,
      target: false
      },
      {
        pass:eye30,
        name:"eye30",
        width:69,
        height:97,
        x:139,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:eye30,
        name:"eye30",
        width:69,
        height:97,
        x:292,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:accessory17,
        name:"accessory17",
        width:389,
        height:142,
        x:56,
        y:316,
        key: 0,
      target: false
      },
    ],
    "🤡":[
      {
        pass:base6,
        name:"base6",
        width:500,
        height:500,
        x:0,
        y:0,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:125,
        y:111,
        key: 0,
      target: false
      },
      {
        pass:eye1,
        name:"eye1",
        width:69,
        height:97,
        x:320,
        y:111,
        key: 0,
      target: false
      },
      {
        pass:accessory2,
        name:"accessory2",
        width:111,
        height:111,
        x:41,
        y:236,
        key: 0,
      target: false
      },
      {
        pass:accessory2,
        name:"accessory2",
        width:111,
        height:111,
        x:346,
        y:236,
        key: 0,
      target: false
      },
      {
        pass:accessory19_3,
        name:"accessory19-3",
        width:97,
        height:97,
        x:208,
        y:222,
        key: 0,
      target: false
      },
      {
        pass:mouth41,
        name:"mouth41",
        width:264,
        height:104,
        x:118,
        y:326,
        key: 0,
      target: false
      },
      {
        pass:accessory19_1,
        name:"accessory19-1",
        width:125,
        height:139,
        x:0,
        y:14,
        key: 0,
      target: false
      },
      {
        pass:accessory19_2,
        name:"accessory19-2",
        width:125,
        height:139,
        x:375,
        y:14,
        key: 0,
      target: false
      },
    ],
  }

  return emojiMenuList
}

export default GetEmojiMenuList;
