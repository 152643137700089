import mouth1 from '../img/mouth/mouth1.svg';
import mouth2 from '../img/mouth/mouth2.svg';
import mouth3 from '../img/mouth/mouth3.svg';
import mouth4 from '../img/mouth/mouth4.svg';
import mouth5 from '../img/mouth/mouth5.svg';
import mouth6 from '../img/mouth/mouth6.svg';
import mouth7 from '../img/mouth/mouth7.svg';
import mouth8 from '../img/mouth/mouth8.svg';
import mouth9 from '../img/mouth/mouth9.svg';
import mouth10 from '../img/mouth/mouth10.svg';
import mouth11 from '../img/mouth/mouth11.svg';
import mouth12 from '../img/mouth/mouth12.svg';
import mouth13 from '../img/mouth/mouth13.svg';
import mouth14 from '../img/mouth/mouth14.svg';
import mouth15 from '../img/mouth/mouth15.svg';
import mouth16 from '../img/mouth/mouth16.svg';
import mouth17 from '../img/mouth/mouth17.svg';
import mouth18 from '../img/mouth/mouth18.svg';
import mouth19 from '../img/mouth/mouth19.svg';
import mouth20 from '../img/mouth/mouth20.svg';
import mouth21 from '../img/mouth/mouth21.svg';
import mouth22 from '../img/mouth/mouth22.svg';
import mouth23 from '../img/mouth/mouth23.svg';
import mouth24 from '../img/mouth/mouth24.svg';
import mouth25 from '../img/mouth/mouth25.svg';
import mouth26 from '../img/mouth/mouth26.svg';
import mouth27 from '../img/mouth/mouth27.svg';
import mouth28 from '../img/mouth/mouth28.svg';
import mouth29 from '../img/mouth/mouth29.svg';
import mouth30 from '../img/mouth/mouth30.svg';
import mouth31 from '../img/mouth/mouth31.svg';
import mouth32 from '../img/mouth/mouth32.svg';
import mouth33 from '../img/mouth/mouth33.svg';
import mouth34 from '../img/mouth/mouth34.svg';
import mouth35 from '../img/mouth/mouth35.svg';
import mouth36 from '../img/mouth/mouth36.svg';
import mouth37 from '../img/mouth/mouth37.svg';
import mouth38 from '../img/mouth/mouth38.svg';
import mouth39 from '../img/mouth/mouth39.svg';
import mouth40 from '../img/mouth/mouth40.svg';
import mouth41 from '../img/mouth/mouth41.svg';
import PartsInfo from "../type/PartsInfo";

const GetMouthMenuList = () => {
  let mouthMenuList:PartsInfo[] = [
    {
      pass:mouth1,
      name:"mouth1",
      width:305,
      height:152,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth2,
      name:"mouth2",
      width:305,
      height:152,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth3,
      name:"mouth3",
      width:348,
      height:348,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth4,
      name:"mouth4",
      width:208,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth5,
      name:"mouth5",
      width:152,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth6,
      name:"mouth6",
      width:264,
      height:84,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth7,
      name:"mouth7",
      width:252,
      height:125,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth8,
      name:"mouth8",
      width:84,
      height:152,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth9,
      name:"mouth9",
      width:333,
      height:140,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth10,
      name:"mouth10",
      width:305,
      height:125,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth11,
      name:"mouth11",
      width:224,
      height:84,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth12,
      name:"mouth12",
      width:119,
      height:46,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth13,
      name:"mouth13",
      width:224,
      height:28,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth14,
      name:"mouth14",
      width:208,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth15,
      name:"mouth15",
      width:183,
      height:71,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth16,
      name:"mouth16",
      width:333,
      height:140,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth17,
      name:"mouth17",
      width:140,
      height:28,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth18,
      name:"mouth18",
      width:152,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth19,
      name:"mouth19",
      width:290,
      height:114,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth20,
      name:"mouth20",
      width:140,
      height:168,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth21,
      name:"mouth21",
      width:119,
      height:137,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth22,
      name:"mouth22",
      width:221,
      height:150,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth23,
      name:"mouth23",
      width:117,
      height:41,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth24,
      name:"mouth24",
      width:180,
      height:58,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth25,
      name:"mouth25",
      width:264,
      height:84,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth26,
      name:"mouth26",
      width:112,
      height:140,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth27,
      name:"mouth27",
      width:84,
      height:97,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth28,
      name:"mouth28",
      width:140,
      height:168,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth29,
      name:"mouth29",
      width:112,
      height:125,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth30,
      name:"mouth30",
      width:140,
      height:224,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth31,
      name:"mouth31",
      width:249,
      height:97,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth32,
      name:"mouth32",
      width:208,
      height:97,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth33,
      name:"mouth33",
      width:140,
      height:56,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth34,
      name:"mouth34",
      width:305,
      height:152,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth35,
      name:"mouth35",
      width:292,
      height:125,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth36,
      name:"mouth36",
      width:249,
      height:84,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth37,
      name:"mouth37",
      width:206,
      height:147,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth38,
      name:"mouth38",
      width:236,
      height:102,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth39,
      name:"mouth39",
      width:333,
      height:140,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth40,
      name:"mouth40",
      width:208,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:mouth41,
      name:"mouth41",
      width:264,
      height:104,
      x:0,
      y:0,
      key: 0,
      target: false
    },
  ]
  return mouthMenuList
}

export default GetMouthMenuList;
