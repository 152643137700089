import {FirebaseApp, initializeApp } from "firebase/app";
import {Firestore, getFirestore } from "firebase/firestore/lite";
import {getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_YOUR_API,
    authDomain: process.env.REACT_APP_YOUR_AUTH,
    projectId: process.env.REACT_APP_YOUR_ID,
    storageBucket: process.env.REACT_APP_YOUR_STORAGE,
};
const app:FirebaseApp =  initializeApp(firebaseConfig);
const storage = getStorage(app);
const db:Firestore = getFirestore(app);

export const GetStorage = () => {
    return storage
}

export const GetDB = () => {
    return db
}