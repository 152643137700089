import React from 'react';
import './App.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SearchIcon from '@mui/icons-material/Search';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import faceIcon from "./img/icon/faceIcon.svg";
import baseIcon from "./img/icon/baseIcon.svg";
import eyebrowIcon from "./img/icon/eyebrowIcon.svg";
import eyeIcon from "./img/icon/eyeIcon.svg";
import mouthIcon from "./img/icon/mouthIcon.svg";
import accessoryIcon from "./img/icon/accessoryIcon.svg";
import coordinate from "./img/coordinate.svg"
import {Rnd} from 'react-rnd';
import html2canvas from "html2canvas";
import GetEmojiMenuList from "./func/GetEmojiMenuList"
import GetEmojiPartsList from "./func/GetEmojiPartsList"
import GetBaseMenuList from "./func/GetBaseMenuList"
import GetEyebrowMenuList from "./func/GetEyebrowMenuList"
import GetEyeMenuList from "./func/GetEyeMenuList"
import GetMouthMenuList from "./func/GetMouthMenuList"
import GetAccessoryMenuList from "./func/GetAccessoryMenuList"
import PostImage from './func/PostImage';
import PartsInfo from "./type/PartsInfo"
import defaltEmoji from "./img/emoji/emoji30.svg"
import Button from '@mui/material/Button';
import NameToPass from './func/NameToPass';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LinkIcon from '@mui/icons-material/Link';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import emoji1 from './img/emoji/emoji1.svg';
import DoneIcon from '@mui/icons-material/Done';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import useImg from './img/use.png';
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  //アラート
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const [snackMessage, setSnackMessage] = React.useState<string>("");

  //使用するBaseとPartsを格納する変数
  const [mainBase, setMainBase] = React.useState<PartsInfo>();
  const [partsList, setPartsList] = React.useState<PartsInfo[]>([]);
  //絵文字作成エリアのRef
  const exportRef = React.useRef<HTMLDivElement>(null);
  //各メニューのmodal表示
  const [isEmojiMenu, setIsEmojiMenu] = React.useState(false);
  const [isBaseMenu, setIsBaseMenu] = React.useState(false);
  const [isEyebrowMenu, setIsEyebrowMenu] = React.useState(false);
  const [isEyeMenu, setIsEyeMenu] = React.useState(false);
  const [isMouthMenu, setIsMouthMenu] = React.useState(false);
  const [isAccessoryMenu, setIsAccessoryMenu] = React.useState(false);
  const [isInputUsername, setIsInputUsername] = React.useState(false);
  const [isSaveMenu, setIsSaveMenu] = React.useState(false);
  const [isUse, setIsUse] = React.useState(false);
  //Partsの数を数え，keyとして利用
  const [usePartsCount, setUsePartsCount] = React.useState(0);
  //事前に入力したデータを取得
  const emojiMenuList = GetEmojiMenuList();
  const emojiPartsList = GetEmojiPartsList();
  const baseMenuList:PartsInfo[] = GetBaseMenuList();
  const eyebrowMenuList:PartsInfo[] = GetEyebrowMenuList();
  const eyeMenuList:PartsInfo[] = GetEyeMenuList();
  const mouthMenuList:PartsInfo[] = GetMouthMenuList();
  const accessoryMenuList:PartsInfo[] = GetAccessoryMenuList();
  //絵文字検索
  const [isSearchMenu, setIsSearchMenu] = React.useState(false);
  const coordinateRef = React.useRef(null);
  const [searchEmojiX, setSearchEmojiX] = React.useState(280);
  const [searchEmojiY, setSearchEmojiY] = React.useState(270);
  const [searchImg, setSearchImg] = React.useState<string>(defaltEmoji);
  const [searchEmoji, setSearchEmoji] = React.useState<string>();
  const [userName, setUserName] = React.useState<string>("");
  const [screenSize, setScreenSize] = React.useState<number>(0);
  const [beforeCode, setBeforeCode] = React.useState<string>("");
  const [resizeEvent, setResizeEvent] = React.useState<number>(0);
  const [saveEmojiURL, setSaveEmojiURL] = React.useState<string>(emoji1);
  const [saveEmojiBlob, setSaveEmojiBlob] = React.useState<Blob|null>(null);
  const [valence, setValence] = React.useState<number>(5);
  const [arousal, setArousal] = React.useState<number>(5);
  const [textAreaValue, setTextAreaValue] = React.useState<string>("");
  const [isStart, setIsStart] = React.useState<boolean>(true);

  //初回実行
  React.useEffect(() => {
    let urlCode = window.location.search.substring(1);
    if(urlCode!==""){
      setBeforeCode(urlCode)
    }
    let widthNum = 0
    if(exportRef.current?.getClientRects()[0]["width"]){
      widthNum = exportRef.current?.getClientRects()[0]["width"]
    }
    setScreenSize(widthNum);
    // console.log(window.location.origin)
  },[])

  React.useEffect(() => {
    const onResize = () => {
      console.log("画面サイズ変更")
      setBeforeCode(CreateEmojiCode(screenSize));
      let widthNum = 0
      if(exportRef.current?.getClientRects()[0]["width"]){
        widthNum = exportRef.current?.getClientRects()[0]["width"]
      }
      // console.log("画面サイズ変更",screenSize,widthNum)
      setScreenSize(widthNum);
      setResizeEvent((resizeEvent+1)%10)
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  },[resizeEvent,partsList,mainBase])

  React.useEffect(() => {
    if(beforeCode!==""){
      // console.log("再描画",isStart)
      if(isStart){
        URLEmojiCodeToParts(beforeCode);
      }
      else{
        EmojiCodeToParts(beforeCode);
      }
    }
    setIsStart(false);
  },[screenSize])

  //画像保存する処理
  const CreateImage = (exportRef:HTMLDivElement) => {
    if(exportRef){
      exportRef.style.backgroundColor = "rgba(255,255,255,0)";
      html2canvas(exportRef, {backgroundColor:"rgba(255,255,255,0)",scale:3}).then(canvas => { 
        const dataUrl = canvas.toDataURL("image/png");
        setSaveEmojiURL(dataUrl)
        setIsSaveMenu(true)
        canvas.toBlob(function (blob) {
          setSaveEmojiBlob(blob);
        })
      });
      exportRef.style.backgroundColor = "rgba(255,255,255,1.0)";
    }
    else{
      console.log("error")
    }
  }

  const saveImage = async() => {
    let downloadEle = document.createElement("a");
    downloadEle.href = saveEmojiURL;
    downloadEle.download = "emoji.png";
    downloadEle.click();
    PostImage(userName,saveEmojiURL,valence,arousal,textAreaValue,URLCreateEmojiCode(screenSize));
    setSnackMessage("絵文字を画像として保存しました");
    handleClick();
  }
  const copyImage = async() => {
    if(saveEmojiBlob){
      navigator.clipboard
        .write([
          new ClipboardItem({
            "image/png": saveEmojiBlob,
          }),
        ])
        .then(function () {})
        .catch(function (error) {
          console.log(error);
        });
      }
    PostImage(userName,saveEmojiURL,valence,arousal,textAreaValue,URLCreateEmojiCode(screenSize));
    setSnackMessage("クリップボードに絵文字画像をコピーしました");
    handleClick();
  }

//Baseを変更する処理
const ChangeMainBase = (parts:PartsInfo) => {
  parts.key = usePartsCount;
  setUsePartsCount(usePartsCount+1)
  setMainBase(parts);
}
//Partsを追加する処理
const AddParts = (parts:PartsInfo) => {
  let tmp = partsList.slice()
  parts.key = usePartsCount;
  setUsePartsCount(usePartsCount+1)
  tmp.push(parts)
  setPartsList(tmp)
}
//Partsを一括削除
const ResetParts = () => {
  setMainBase(undefined);
  setPartsList([]);
}
//絵文字選択でParts追加する
const SetEmojiParts = (emojiStr:string) => {
  for (const [key, value] of Object.entries(emojiPartsList)) {
    if(key===emojiStr){
      ResetParts();
      let tmpList:PartsInfo[] = [];
      let tmpCount = usePartsCount;
      value.forEach((parts: PartsInfo) => {
        if(parts.name.slice(0,4)==="base"){
          parts.key = tmpCount;
          tmpCount += 1;
          setMainBase(parts);
        }
        else{
          parts.key = tmpCount;
          tmpCount += 1;
          tmpList.push(parts);
        }
      });
      setUsePartsCount(tmpCount)
      setPartsList(tmpList)
    }
  }
}

const EmojiCodeToParts = (emojiCode:string) => {
  if(emojiCode===""){
    return ""
  }
  //変換
  // console.log("絵文字コードからパーツに変換",emojiCode)
  let emojiCodeFix1 = emojiCode.replace(/[[]/g, "");
  let emojiCodeFix2 = emojiCodeFix1.slice( 0, -1 ) ;
  let emojiCodeList = emojiCodeFix2.split("]")
  let codePartsList:PartsInfo[] = []
  emojiCodeList.map((value,index) => {
    let param = value.split(",")
    // console.log(param)
    let part:PartsInfo = {
      pass:NameToPass(param[0]),
      name:param[0],
      width:Number(param[1]),
      height:Number(param[2]),
      x:Number(param[3]),
      y:Number(param[4]),
      key: 0,
      target: false
    }
    codePartsList.push(part)
    //エラーチェック
    if(emojiCode===""){
      return ""
    }
    return ""
  })
  //パーツを反映
  ResetParts();
  let tmpList:PartsInfo[] = [];
  let tmpCount = usePartsCount;
  codePartsList.forEach((parts: PartsInfo) => {
    if(parts.name.slice(0,4)==="base"){
      parts.key = tmpCount;
      tmpCount += 1;
      setMainBase(parts);
    }
    else{
      parts.key = tmpCount;
      tmpCount += 1;
      tmpList.push(parts);
    }
  });
  // console.log(tmpList)
  setUsePartsCount(tmpCount)
  setPartsList(tmpList)
}
const URLEmojiCodeToParts = (emojiCode:string) => {
  //正規表現かどうかを確認
  const codeExp = /^(\[[^[\],]+(,[0-9]+){4}\])*$/
  let checkResult = codeExp.test(emojiCode)
  // console.log(checkResult)
  if(checkResult===false){
    console.log("[name(文字),width(数字),height(数字),x(数字),y(数字)]にして下さい")
    alert("[name(文字),width(数字),height(数字),x(数字),y(数字)]にして下さい");
    return ""
  }
  //変換
  // console.log("絵文字コードからパーツに変換",emojiCode)
  let emojiCodeFix1 = emojiCode.replace(/[[]/g, "");
  let emojiCodeFix2 = emojiCodeFix1.slice( 0, -1 ) ;
  let emojiCodeList = emojiCodeFix2.split("]")
  let codePartsList:PartsInfo[] = []
  //エラーチェック：name，pass，width，height，x，y
  let errorCheckList = [false,false,false,false,false,false]
  emojiCodeList.map((value,index) => {
    let param = value.split(",")
    // console.log(param)
    let part:PartsInfo = {
      pass:NameToPass(param[0]),
      name:param[0],
      width:Number(param[1]),
      height:Number(param[2]),
      x:Number(param[3]),
      y:Number(param[4]),
      key: 0,
      target: false
    }
    codePartsList.push(part)
    //エラーチェック
    if(emojiCode===""){
      return ""
    }
    if(index===0 && param[0].slice(0,4)!=="base"){
      errorCheckList[0] = true
    }
    if(NameToPass(param[0])==="none"){
      errorCheckList[1] = true
    }
    if(0>=Number(param[1]) || Number(param[1])>500){
      errorCheckList[2] = true
    }
    if(0>=Number(param[2]) || Number(param[2])>500){
      errorCheckList[3] = true
    }
    if(0>Number(param[3]) || Number(param[3])>(500-Number(param[1]))){
      errorCheckList[4] = true
    }
    if(0>Number(param[4]) || Number(param[4])>(500-Number(param[2]))){
      errorCheckList[5] = true
    }
    return ""
  })
  if(errorCheckList[0]){
    console.log("1つ目の要素はbaseにしてください")
    alert("1つ目の要素はbaseにして下さい");
    return ""
  }
  if(errorCheckList[1]){
    console.log("不明な要素が含まれています")
    alert("不明な要素が含まれています");
    return ""
  }
  if(errorCheckList[2]){
    console.log("widthは1~500にしてください",emojiCode)
    alert("widthは1~500にしてください")
    return ""
  }
  if(errorCheckList[3]){
    console.log("heightは1~500にしてください")
    alert("heightは1~500にしてください")
    return ""
  }
  if(errorCheckList[4]){
    console.log("xが座標外になっています")
    alert("xが座標外になっています")
    return ""
  }
  if(errorCheckList[5]){
    console.log("yが座標外になっています")
    alert("yが座標外になっています");
    return ""
  }
  //パーツを反映
  ResetParts();
  let tmpList:PartsInfo[] = [];
  let tmpCount = usePartsCount;
  codePartsList.forEach((parts: PartsInfo) => {
    if(parts.name.slice(0,4)==="base"){
      parts.key = tmpCount;
      tmpCount += 1;
      setMainBase(parts);
    }
    else{
      parts.key = tmpCount;
      tmpCount += 1;
      tmpList.push(parts);
    }
  });
  // console.log(tmpList)
  setUsePartsCount(tmpCount)
  setPartsList(tmpList)
}

const CreateEmojiCode = (screenSize:number) => {
  //コード発行 Ex:[name,width,x,y],[name,width,x,y]....
  let createEmojiCode = ""
  let parentStyle = exportRef.current?.getClientRects()
  let partsCollection = exportRef.current?.children
  let partsElementArray = partsCollection!==undefined ? Array.from(partsCollection) : [];
  partsElementArray.map((element,index) => {
    let name = ""
    if(mainBase===undefined){
      name = partsList[index].name;
    }
    else{
      name = index===0 ? mainBase?.name : partsList[index-1].name;
    }
    let childStyle = element.getClientRects()
    let width = childStyle?.item(0)?.width
    width = width!==undefined ? width/screenSize*500 : width;
    let height = childStyle?.item(0)?.height
    height = height!==undefined ? height/screenSize*500 : height;
    let x = 0
    let parentX = parentStyle?.item(0)?.x
    let childX = childStyle?.item(0)?.x 
    if(parentX!==undefined && childX!==undefined){x = (childX-parentX)/screenSize*500}
    let y = 0
    let parentY = parentStyle?.item(0)?.y
    let childY = childStyle?.item(0)?.y 
    if(parentY!==undefined && childY!==undefined){y = (childY - parentY)/screenSize*500}
    createEmojiCode += `[${name},${width},${height},${x},${y}]`
    return ""
  })
  console.log("絵文字コード発行:"+createEmojiCode)
  return createEmojiCode
}

const URLCreateEmojiCode = (screenSize:number) => {
  //コード発行 Ex:[name,width,x,y],[name,width,x,y]....
  let createEmojiCode = ""
  let parentStyle = exportRef.current?.getClientRects()
  let partsCollection = exportRef.current?.children
  let partsElementArray = partsCollection!==undefined ? Array.from(partsCollection) : [];
  partsElementArray.map((element,index) => {
    let name = ""
    if(mainBase===undefined){
      name = partsList[index].name;
    }
    else{
      name = index===0 ? mainBase?.name : partsList[index-1].name;
    }
    let childStyle = element.getClientRects()
    let width = childStyle?.item(0)?.width
    width = width!==undefined ? Math.floor(width/screenSize*500) : width;
    let height = childStyle?.item(0)?.height
    height = height!==undefined ? Math.floor(height/screenSize*500) : height;
    let x = 0
    let parentX = parentStyle?.item(0)?.x
    let childX = childStyle?.item(0)?.x 
    if(parentX!==undefined && childX!==undefined){x = Math.floor((childX-parentX)/screenSize*500)}
    let y = 0
    let parentY = parentStyle?.item(0)?.y
    let childY = childStyle?.item(0)?.y 
    if(parentY!==undefined && childY!==undefined){y = Math.floor((childY - parentY)/screenSize*500)}
    createEmojiCode += `[${name},${width},${height},${x},${y}]`
    return ""
  })
  console.log("絵文字コード発行:"+createEmojiCode)
  return createEmojiCode
}

  return (
    <div id="body">
      {/* Topバー */}
      <AppBar position="static">
        <Toolbar style={{minHeight:"5vh",maxHeight:"5vh"}}>
          <SentimentSatisfiedAltIcon fontSize="large" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>Emo Maker</Typography>
          <IconButton aria-label="guide" style={{color:"white"}} onClick={()=>{setIsUse(true)}}>
            <HelpCenterIcon style={{fontSize:"3.8vh"}}/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <div id="main">
        {/* リストエリア */}
        <div id="listArea">
          <IconButton onClick={() => {setIsEmojiMenu(true)}}><img className='iconImg' src={faceIcon} alt="icon"/></IconButton>
          <IconButton onClick={() => {setIsBaseMenu(true)}}><img className='iconImg' src={baseIcon} alt="icon"/></IconButton>
          <IconButton onClick={() => {setIsEyebrowMenu(true)}}><img className='iconImg' src={eyebrowIcon} alt="icon"/></IconButton>
          <IconButton onClick={() => {setIsEyeMenu(true)}}><img className='iconImg' src={eyeIcon} alt="icon"/></IconButton>
          <IconButton onClick={() => {setIsMouthMenu(true)}}><img className='iconImg' src={mouthIcon} alt="icon"/></IconButton>
          <IconButton onClick={() => {setIsAccessoryMenu(true)}}><img className='iconImg' src={accessoryIcon} alt="icon"/></IconButton>
          <IconButton onClick={() => {setIsSearchMenu(true)}}><SearchIcon/></IconButton>
          <IconButton onClick={() => {ResetParts()}}><DeleteForeverIcon/></IconButton>
          <List>
            {mainBase!==undefined ? 
              <ListItem>
                <ListItemAvatar><Avatar style={{borderRadius:0}} alt="listImage" src={mainBase.pass} /></ListItemAvatar>
                <ListItemText primary={mainBase.name}/>
              </ListItem>
              : undefined
            }
            {mainBase!==undefined ? <Divider/> : undefined}

            {partsList.map((parts: PartsInfo,index) => {
              if(parts.name!==""){
                let styleName = "avatar_width"
                if(parts.width<parts.height){
                  styleName = "avatar_height"
                }
                return (
                  <ListItem key={index}
                    onMouseEnter={() => {
                      let tmp = partsList.slice();
                      tmp[index].target = true;
                      setPartsList(tmp);
                    }}
                    onMouseLeave={() => {
                      let tmp = partsList.slice();
                      tmp[index].target = false;
                      setPartsList(tmp);
                    }}
                    secondaryAction={
                    <>
                    <IconButton edge="end" aria-label="less" onClick={()=>{if(index!==0){let tmpList = partsList.slice();let tmp = tmpList[index];tmpList[index] = tmpList[index-1];tmpList[index-1] = tmp;tmpList[index-1].target=false;tmpList[index].target=true;setPartsList(tmpList);}}}><ExpandLessIcon fontSize='large'/></IconButton>
                    <IconButton edge="end" aria-label="more" onClick={()=>{if(index!==partsList.length-1){let tmpList = partsList.slice();let tmp = tmpList[index];tmpList[index] = tmpList[index+1];tmpList[index+1] = tmp;tmpList[index+1].target=false;tmpList[index].target=true;setPartsList(tmpList);}}}><ExpandMoreIcon fontSize='large'/></IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={()=>{let tmp=partsList.slice();tmp.splice(index,1);setPartsList(tmp);}}><DeleteIcon fontSize='large'/></IconButton>
                    </>
                    }>
                    <ListItemAvatar><Avatar className={styleName} style={{borderRadius:0}} alt="listImage" src={parts.pass} /></ListItemAvatar>
                    <ListItemText primary={parts.name}/>
                  </ListItem>
                );
              }
              else{
                return(undefined)
              }
            })}
          </List>
        </div>
        {/* 絵文字作成エリア */}
        <div id="emojiArea">
          <div id="create_area" ref={exportRef}>
            {mainBase!==undefined ? 

            <Rnd key={mainBase.key} style={{aspectRatio:mainBase.width/mainBase.height}} 
            default={{x: mainBase.x/500*screenSize,y: mainBase.y/500*screenSize,width: mainBase.width/500*screenSize,height: "auto"}} bounds="parent" 
            enableResizing={{top: false, right: true, bottom: false, left: true,topRight: false, bottomRight: false, bottomLeft: false, topLeft: false}}>
            <div className='imgStyle' style={{backgroundImage:`url(${mainBase.pass})`}}></div>
            </Rnd>

            // <Rnd key={mainBase.key} style={mainBase.target===true ? {aspectRatio:mainBase.width/mainBase.height,backgroundColor:"rgba(250, 100, 100, 0.3)"
            // } : {aspectRatio:mainBase.width/mainBase.height,backgroundColor:"rgba(250, 100, 100, 0)"}}
            //   default={{x: mainBase.x/500*screenSize,y: mainBase.y/500*screenSize,width: mainBase.width/500*screenSize,height: "auto"}} bounds="parent" 
            //   enableResizing={{top: false, right: true, bottom: false, left: true,topRight: false, bottomRight: false, bottomLeft: false, topLeft: false}}>
            //   <div className='imgStyle' style={{backgroundImage:`url(${mainBase.pass})`}}
            //     onMouseEnter={() => {
            //       let tmp = { ...mainBase };
            //       tmp.target = true;
            //       setMainBase(tmp);
            //       console.log("aaa")
            //     }}
            //     onMouseLeave={() => {
            //       let tmp = { ...mainBase };
            //       tmp.target = false;
            //       setMainBase(tmp);
            //       console.log("aaa")
            //     }}>
            //   </div>
            // </Rnd>

            :undefined}
            {partsList.map((parts: PartsInfo,index) => {
              if(parts.name!==""){
                let rndStyle = {
                  aspectRatio:parts.width/parts.height,
                  backgroundColor:"none"
                }
                if(parts.target){
                  rndStyle = {
                    aspectRatio:parts.width/parts.height,
                    backgroundColor:"rgba(250, 100, 100, 0.3)"
                  }
                }
                else{
                  rndStyle = {
                    aspectRatio:parts.width/parts.height,
                    backgroundColor:"rgba(250, 100, 100, 0)"
                  }
                }
                return (
                  <Rnd key={parts.key} style={rndStyle} 
                    default={{x: parts.x/500*screenSize,y: parts.y/500*screenSize,width: parts.width/500*screenSize,height: "auto"}} bounds="parent"
                    enableResizing={{top: false, right: true, bottom: false, left: true,topRight: false, bottomRight: false, bottomLeft: false, topLeft: false}}>
                    <div className='imgStyle' style={{backgroundImage:`url(${parts.pass})`}} 
                      onMouseEnter={() => {
                        let tmp = partsList.slice();
                        tmp[index].target = true;
                        setPartsList(tmp);
                      }}
                      onMouseLeave={() => {
                        let tmp = partsList.slice();
                        tmp[index].target = false;
                        setPartsList(tmp);
                      }} 
                      onTouchStart={() => {
                        let tmp = partsList.slice();
                        for(let i=0; i<tmp.length;i++){
                          tmp[i].target = false
                        }
                        tmp[index].target = true;
                        setPartsList(tmp);
                      }}
                      onTouchEnd={() => {
                        let tmp = partsList.slice();
                        tmp[index].target = false;
                        setPartsList(tmp);
                      }}
                    >
                      
                    </div>
                  </Rnd>
                )
              }
              else{
                return(undefined)
              }
            })}
          </div>
          <div id="buttonDiv">
            <Button id="saveBtn" size="large" variant="contained" startIcon={<SaveAltIcon />} onClick={() => {if(exportRef.current!==null){CreateImage(exportRef.current)}}}>
              絵文字を保存・コピー
            </Button>
            <Button id="linkBtn" size="large" variant="contained" startIcon={<LinkIcon />} onClick={() => {
              if (!navigator.clipboard) {alert("使用中のブラウザではコピーできません");return;}
              let emojiCode = window.location.origin + "?"+URLCreateEmojiCode(screenSize)
              // let emojiCode = "http://localhost:3000/?"+URLCreateEmojiCode(screenSize)
              navigator.clipboard.writeText(emojiCode).then(
                () => {console.log("絵文字コードをコピー");setSnackMessage("絵文字を共有するURLをコピーしました");handleClick();},
                () => {alert('絵文字コードのコピーに失敗しました');}
              );
            }}>
              絵文字を共有
            </Button>
          </div>
        </div>
      </div>

      {/* 絵文字選択 */}
      <Modal open={isEmojiMenu} onClose={() => {setIsEmojiMenu(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <ImageList sx={{ width: 420, height: 500 }} cols={4} rowHeight={100} style={{backgroundColor:"rgba(200, 200, 200, 0.8)"}}>
          {emojiMenuList.map((info) => (
            <ImageListItem key={info.pass}>
              <img src={info.pass} alt="parts" onClick={()=>{setIsEmojiMenu(false);SetEmojiParts(info.name);}}/>
            </ImageListItem>
          ))}
        </ImageList>
      </Modal>
      
      {/* Base選択 */}
      <Modal open={isBaseMenu} onClose={() => {setIsBaseMenu(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <ImageList sx={{ width: 420, height: 500 }} cols={4} rowHeight={100} style={{backgroundColor:"rgba(200, 200, 200, 0.8)"}}>
          {baseMenuList.map((info) => (
            <ImageListItem key={info.pass}>
              <img src={info.pass} alt="parts" onClick={()=>{setIsBaseMenu(false);ChangeMainBase(info);}}/>
            </ImageListItem>
          ))}
        </ImageList>
      </Modal>

      {/* 眉毛選択 */}
      <Modal open={isEyebrowMenu} onClose={() => {setIsEyebrowMenu(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <ImageList sx={{ width: 420, height: 500 }} cols={4} rowHeight={100} style={{backgroundColor:"rgba(200, 200, 200, 0.8)"}}>
          {eyebrowMenuList.map((info) => {
            if(info.width>=info.height){
              let imgRate = info.width/100
              let marginHeight = (100-info.height/imgRate)/2
              return(
              <ImageListItem key={info.pass} style={{width:info.width/imgRate,height:"fit-content", marginTop:`${marginHeight}px`, marginBottom:`${marginHeight}px`}}>
                <img src={info.pass} alt="parts" onClick={()=>{setIsEyebrowMenu(false);AddParts(info);}}/>
              </ImageListItem>
              )
            }
            else{
              let imgRate = info.height/100
              let marginWidth = (100-info.width/imgRate)/2
              return(
              <ImageListItem key={info.pass} style={{width:"fit-content",height:info.height/imgRate, marginLeft:`${marginWidth}px`, marginRight:`${marginWidth}px`}}>
                <img src={info.pass} alt="parts" onClick={()=>{setIsEyebrowMenu(false);AddParts(info);}}/>
              </ImageListItem>
              )
            }
          })}
        </ImageList>
      </Modal>

      {/* 目選択 */}
      <Modal open={isEyeMenu} onClose={() => {setIsEyeMenu(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <ImageList sx={{ width: 420, height: 500 }} cols={4} rowHeight={100} style={{backgroundColor:"rgba(200, 200, 200, 0.8)"}}>
          {eyeMenuList.map((info) => {
            if(info.width>=info.height){
              let imgRate = info.width/100
              let marginHeight = (100-info.height/imgRate)/2
              return(
              <ImageListItem key={info.pass} style={{width:info.width/imgRate,height:"fit-content", marginTop:`${marginHeight}px`, marginBottom:`${marginHeight}px`}}>
                <img src={info.pass} alt="parts" onClick={()=>{setIsEyeMenu(false);AddParts(info);}}/>
              </ImageListItem>
              )
            }
            else{
              let imgRate = info.height/100
              let marginWidth = (100-info.width/imgRate)/2
              return(
              <ImageListItem key={info.pass} style={{width:"fit-content",height:info.height/imgRate, marginLeft:`${marginWidth}px`, marginRight:`${marginWidth}px`}}>
                <img src={info.pass} alt="parts" onClick={()=>{setIsEyeMenu(false);AddParts(info);}}/>
              </ImageListItem>
              )
            }
          })}
        </ImageList>
      </Modal>

      {/* 口選択 */}
      <Modal open={isMouthMenu} onClose={() => {setIsMouthMenu(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <ImageList sx={{ width: 420, height: 500 }} cols={4} rowHeight={100} style={{backgroundColor:"rgba(200, 200, 200, 0.8)"}}>
          {mouthMenuList.map((info) => {
            if(info.width>=info.height){
              let imgRate = info.width/100
              let marginHeight = (100-info.height/imgRate)/2
              return(
              <ImageListItem key={info.pass} style={{width:info.width/imgRate,height:"fit-content", marginTop:`${marginHeight}px`, marginBottom:`${marginHeight}px`}}>
                <img src={info.pass} alt="parts" onClick={()=>{setIsMouthMenu(false);AddParts(info);}}/>
              </ImageListItem>
              )
            }
            else{
              let imgRate = info.height/100
              let marginWidth = (100-info.width/imgRate)/2
              return(
              <ImageListItem key={info.pass} style={{width:"fit-content",height:info.height/imgRate, marginLeft:`${marginWidth}px`, marginRight:`${marginWidth}px`}}>
                <img src={info.pass} alt="parts" onClick={()=>{setIsMouthMenu(false);AddParts(info);}}/>
              </ImageListItem>
              )
            }
          })}
        </ImageList>
      </Modal>

      {/* アクセサリー選択 */}
      <Modal open={isAccessoryMenu} onClose={() => {setIsAccessoryMenu(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <ImageList sx={{ width: 420, height: 500 }} cols={4} rowHeight={100} style={{backgroundColor:"rgba(200, 200, 200, 0.8)"}}>
          {accessoryMenuList.map((info) => {
            if(info.width>=info.height){
              let imgRate = info.width/100
              let marginHeight = (100-info.height/imgRate)/2
              return(
              <ImageListItem key={info.pass} style={{width:info.width/imgRate,height:"fit-content", marginTop:`${marginHeight}px`, marginBottom:`${marginHeight}px`}}>
                <img src={info.pass} alt="parts" onClick={()=>{setIsAccessoryMenu(false);AddParts(info);}}/>
              </ImageListItem>
              )
            }
            else{
              let imgRate = info.height/100
              let marginWidth = (100-info.width/imgRate)/2
              return(
              <ImageListItem key={info.pass} style={{width:"fit-content",height:info.height/imgRate, marginLeft:`${marginWidth}px`, marginRight:`${marginWidth}px`}}>
                <img src={info.pass} alt="parts" onClick={()=>{setIsAccessoryMenu(false);AddParts(info);}}/>
              </ImageListItem>
              )
            }
          })}
        </ImageList>
      </Modal>

      {/* 絵文字検索 */}
      <Modal open={isSearchMenu} onClose={() => {setIsSearchMenu(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div id="coordinateDiv">
          <img src={coordinate} id="absolute" width="650" height="650" alt="coordinateImg"></img>
          <canvas id="absolute" width="650" height="650" ref={coordinateRef}
          onMouseMove={(e) => {
            // console.log(e.nativeEvent.offsetX,e.nativeEvent.offsetY)
            setSearchEmojiX(e.nativeEvent.offsetX-50);
            setSearchEmojiY(e.nativeEvent.offsetY-125);
            let nowX = ((e.nativeEvent.offsetX)/650*8)+1;
            let nowY = 10-(((e.nativeEvent.offsetY)/650*8)+1);
            // console.log(nowX,nowY)
            let minPass = "";
            let minDiff = Infinity;
            let minEmoji = "";
            emojiMenuList.forEach((emoji,index) => {
              let targetDiff = ((nowX-emoji.valence)**2+(nowY-emoji.arousal)**2)**0.5
              if(minDiff>targetDiff){
                minDiff = targetDiff;
                minPass = emoji.pass;
                minEmoji = emoji.name;
              }
            })
            setSearchImg(minPass);
            setSearchEmoji(minEmoji);
          }}
          onMouseDown={() => {setIsSearchMenu(false);if(searchEmoji!==undefined){SetEmojiParts(searchEmoji);}}}
          ></canvas>
          <img id="searchResult" src={searchImg} style={{left:searchEmojiX,top:searchEmojiY}} alt="searchImg" onMouseEnter={(e) => {setSearchEmojiY(searchEmojiY-75)}}></img>
        </div>
      </Modal>

      {/* ユーザー名入力 */}
      {/* <Modal open={isInputUsername} onClose={() => {setIsInputUsername(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Input defaultValue={userName} onChange={(event) => {setUserName(event.target.value);}} style={{width:"30vw", marginLeft:"auto", marginRight:"auto", marginTop:"30vh"}} size="lg" placeholder="ユーザ名を入力してください"/>
      </Modal> */}

      {/* 保存画面 */}
      <Modal open={isSaveMenu} onClose={() => {setIsSaveMenu(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box id="saveMenu">
          <img alt="none" id="saveEmoji" src={saveEmojiURL}></img>
          <div className='slider'>
            <div id="valence">快・不快をどの程度表していますか？<br/>(数字が大きいほど快を意味します)</div>
            <Slider onChangeCommitted={(e,value)=>{if(typeof(value)==="number"){setValence(value)}}} aria-label="Temperature" defaultValue={5} valueLabelDisplay="auto" step={1} min={1} max={9} marks={[{value: 1,label: "不快"},{value: 9,label: "快"}]}/>
          </div>
          <div className='slider'>
            <div id="arousal">感情の強さをどの程度表していますか？<br/>(数字が大きいほど強い感情を意味します)</div>
            <Slider onChangeCommitted={(e,value)=>{if(typeof(value)==="number"){setArousal(value)}}} aria-label="Temperature" defaultValue={5} valueLabelDisplay="auto" step={1} min={1} max={9} marks={[{value: 1,label: "弱い"},{value: 9,label: "強い"}]}/>
          </div>
          <div id="textAreaDiv">
            <div id="textfieldTitle">どのような時に使いますか？</div>
            <textarea id="explanatoryText" onChange={(e)=>{setTextAreaValue(e.target.value)}}></textarea>
          </div>
          <div id='finishBtnDiv'>
            <Button id="finishBtn" size="large" variant="contained" startIcon={<DoneIcon />} onClick={() => {setIsSaveMenu(false);saveImage();}}>
              保存
            </Button>
            <Button id="copyBtn" size="large" variant="contained" startIcon={<ContentCopyIcon />} onClick={() => {setIsSaveMenu(false);copyImage();}}>
              コピー
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal open={isUse} onClose={() => {setIsUse(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div id="useImgDiv"><img alt="none" id="useImg" src={useImg} onClick={()=>{setIsUse(false)}}></img></div>
      </Modal>

      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical:"top", horizontal:"center" }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;