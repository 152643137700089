import accessory1_1 from '../img/accessory/accessory1-1.svg';
import accessory1_2 from '../img/accessory/accessory1-2.svg';
import accessory1_3 from '../img/accessory/accessory1-3.svg';
import accessory2 from '../img/accessory/accessory2.svg';
import accessory3 from '../img/accessory/accessory3.svg';
import accessory4_1 from '../img/accessory/accessory4-1.svg';
import accessory4_2 from '../img/accessory/accessory4-2.svg';
import accessory4_3 from '../img/accessory/accessory4-3.svg';
import accessory4_4 from '../img/accessory/accessory4-4.svg';
import accessory5_1 from '../img/accessory/accessory5-1.svg';
import accessory5_2 from '../img/accessory/accessory5-2.svg';
import accessory5_3 from '../img/accessory/accessory5-3.svg';
import accessory6_1 from '../img/accessory/accessory6-1.svg';
import accessory6_2 from '../img/accessory/accessory6-2.svg';
import accessory7_1 from '../img/accessory/accessory7-1.svg';
import accessory7_2 from '../img/accessory/accessory7-2.svg';
import accessory7_3 from '../img/accessory/accessory7-3.svg';
import accessory8_1 from '../img/accessory/accessory8-1.svg';
import accessory8_2 from '../img/accessory/accessory8-2.svg';
import accessory8_3 from '../img/accessory/accessory8-3.svg';
import accessory8_4 from '../img/accessory/accessory8-4.svg';
import accessory9 from '../img/accessory/accessory9.svg';
import accessory10_1 from '../img/accessory/accessory10-1.svg';
import accessory10_2 from '../img/accessory/accessory10-2.svg';
import accessory10_3 from '../img/accessory/accessory10-3.svg';
import accessory10_4 from '../img/accessory/accessory10-4.svg';
import accessory11 from '../img/accessory/accessory11.svg';
import accessory12 from '../img/accessory/accessory12.svg';
import accessory13 from '../img/accessory/accessory13.svg';
import accessory14_1 from '../img/accessory/accessory14-1.svg';
import accessory14_2 from '../img/accessory/accessory14-2.svg';
import accessory15_1 from '../img/accessory/accessory15-1.svg';
import accessory15_2 from '../img/accessory/accessory15-2.svg';
import accessory16_1 from '../img/accessory/accessory16-1.svg';
import accessory16_2 from '../img/accessory/accessory16-2.svg';
import accessory17 from '../img/accessory/accessory17.svg';
import accessory18_1 from '../img/accessory/accessory18-1.svg';
import accessory18_2 from '../img/accessory/accessory18-2.svg';
import accessory18_3 from '../img/accessory/accessory18-3.svg';
import accessory18_4 from '../img/accessory/accessory18-4.svg';
import accessory18_5 from '../img/accessory/accessory18-5.svg';
import accessory18_6 from '../img/accessory/accessory18-6.svg';
import accessory19_1 from '../img/accessory/accessory19-1.svg';
import accessory19_2 from '../img/accessory/accessory19-2.svg';
import accessory19_3 from '../img/accessory/accessory19-3.svg';
import PartsInfo from "../type/PartsInfo";

const GetAccessoryMenuList = () => {
  let accessoryMenuList:PartsInfo[] = [
    {
      pass:accessory1_1,
      name:"accessory1-1",
      width:140,
      height:208,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory1_2,
      name:"accessory1-2",
      width:139,
      height:207,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory1_3,
      name:"accessory1-3",
      width:207,
      height:139,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory2,
      name:"accessory2",
      width:140,
      height:140,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory3,
      name:"accessory3",
      width:495,
      height:152,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory4_1,
      name:"accessory4-1",
      width:173,
      height:147,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory4_2,
      name:"accessory4-2",
      width:190,
      height:151,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory4_3,
      name:"accessory4-3",
      width:137,
      height:105,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory4_4,
      name:"accessory4-4",
      width:171,
      height:155,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory5_1,
      name:"accessory5-1",
      width:230,
      height:221,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory5_2,
      name:"accessory5-2",
      width:230,
      height:221,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory5_3,
      name:"accessory5-3",
      width:167,
      height:150,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory6_1,
      name:"accessory6-1",
      width:236,
      height:239,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory6_2,
      name:"accessory6-2",
      width:236,
      height:239,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory7_1,
      name:"accessory7-1",
      width:168,
      height:180,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory7_2,
      name:"accessory7-2",
      width:176,
      height:156,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory7_3,
      name:"accessory7-3",
      width:167,
      height:122,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory8_1,
      name:"accessory8-1",
      width:234,
      height:223,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory8_2,
      name:"accessory8-2",
      width:234,
      height:223,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory8_3,
      name:"accessory8-3",
      width:277,
      height:273,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory8_4,
      name:"accessory8-4",
      width:266,
      height:252,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory9,
      name:"accessory9",
      width:180,
      height:180,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory10_1,
      name:"accessory10-1",
      width:58,
      height:163,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory10_2,
      name:"accessory10-2",
      width:61,
      height:157,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory10_3,
      name:"accessory10-3",
      width:55,
      height:201,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory10_4,
      name:"accessory10-4",
      width:73,
      height:270,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory11,
      name:"accessory11",
      width:498,
      height:208,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory12,
      name:"accessory12",
      width:470,
      height:145,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory13,
      name:"accessory13",
      width:249,
      height:412,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory14_1,
      name:"accessory14-1",
      width:56,
      height:264,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory14_2,
      name:"accessory14-2",
      width:500,
      height:56,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory15_1,
      name:"accessory15-1",
      width:104,
      height:236,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory15_2,
      name:"accessory15-2",
      width:104,
      height:236,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory16_1,
      name:"accessory16-1",
      width:196,
      height:224,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory16_2,
      name:"accessory16-2",
      width:196,
      height:224,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory17,
      name:"accessory17",
      width:389,
      height:142,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory18_1,
      name:"accessory18-1",
      width:239,
      height:239,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory18_2,
      name:"accessory18-2",
      width:274,
      height:129,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory18_3,
      name:"accessory18-3",
      width:42,
      height:42,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory18_4,
      name:"accessory18-4",
      width:56,
      height:56,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory18_5,
      name:"accessory18-5",
      width:62,
      height:84,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory18_6,
      name:"accessory18-6",
      width:56,
      height:97,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory19_1,
      name:"accessory19-1",
      width:125,
      height:140,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory19_2,
      name:"accessory19-2",
      width:125,
      height:140,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:accessory19_3,
      name:"accessory19-3",
      width:97,
      height:97,
      x:0,
      y:0,
      key: 0,
      target: false
    },
  ]
  return accessoryMenuList
}

export default GetAccessoryMenuList;
