import eye1 from '../img/eye/eye1.svg';
import eye2 from '../img/eye/eye2.svg';
import eye3 from '../img/eye/eye3.svg';
import eye4 from '../img/eye/eye4.svg';
import eye5left from '../img/eye/eye5-left.svg';
import eye5right from '../img/eye/eye5-right.svg';
import eye6left from '../img/eye/eye6-left.svg';
import eye6right from '../img/eye/eye6-right.svg';
import eye7 from '../img/eye/eye7.svg';
import eye8 from '../img/eye/eye8.svg';
import eye9left from '../img/eye/eye9-left.svg';
import eye9right from '../img/eye/eye9-right.svg';
import eye10 from '../img/eye/eye10.svg';
import eye11 from '../img/eye/eye11.svg';
import eye12 from '../img/eye/eye12.svg';
import eye13 from '../img/eye/eye13.svg';
import eye14 from '../img/eye/eye14.svg';
import eye15 from '../img/eye/eye15.svg';
import eye16 from '../img/eye/eye16.svg';
import eye17 from '../img/eye/eye17.svg';
import eye18 from '../img/eye/eye18.svg';
import eye19 from '../img/eye/eye19.svg';
import eye20left from '../img/eye/eye20-left.svg';
import eye20right from '../img/eye/eye20-right.svg';
import eye21 from '../img/eye/eye21.svg';
import eye22 from '../img/eye/eye22.svg';
import eye23left from '../img/eye/eye23-left.svg';
import eye23right from '../img/eye/eye23-right.svg';
import eye24left from '../img/eye/eye24-left.svg';
import eye24right from '../img/eye/eye24-right.svg';
import eye25left from '../img/eye/eye25-left.svg';
import eye25right from '../img/eye/eye25-right.svg';
import eye26left from '../img/eye/eye26-left.svg';
import eye26right from '../img/eye/eye26-right.svg';
import eye27left from '../img/eye/eye27-left.svg';
import eye27right from '../img/eye/eye27-right.svg';
import eye28 from '../img/eye/eye28.svg';
import eye29left from '../img/eye/eye29-left.svg';
import eye29right from '../img/eye/eye29-right.svg';
import eye30 from '../img/eye/eye30.svg';
import eye31 from '../img/eye/eye31.svg';
import PartsInfo from "../type/PartsInfo";

const GetEyeMenuList = () => {
  let eyeMenuList:PartsInfo[] = [
    {
      pass:eye1,
      name:"eye1",
      width:69,
      height:97,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye2,
      name:"eye2",
      width:69,
      height:152,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye3,
      name:"eye3",
      width:125,
      height:56,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye4,
      name:"eye4",
      width:112,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye5left,
      name:"eye5-left",
      width:152,
      height:112,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye5right,
      name:"eye5-right",
      width:152,
      height:112,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye6left,
      name:"eye6-left",
      width:136,
      height:146,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye6right,
      name:"eye6-right",
      width:146,
      height:136,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye7,
      name:"eye7",
      width:140,
      height:56,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye8,
      name:"eye8",
      width:180,
      height:180,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye9left,
      name:"eye9-left",
      width:112,
      height:46,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye9right,
      name:"eye9-right",
      width:112,
      height:46,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye10,
      name:"eye10",
      width:125,
      height:41,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye11,
      name:"eye11",
      width:107,
      height:51,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye12,
      name:"eye12",
      width:58,
      height:74,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye13,
      name:"eye13",
      width:69,
      height:76,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye14,
      name:"eye14",
      width:125,
      height:28,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye15,
      name:"eye15",
      width:125,
      height:41,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye16,
      name:"eye16",
      width:152,
      height:152,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye17,
      name:"eye17",
      width:125,
      height:56,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye18,
      name:"eye18",
      width:125,
      height:41,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye19,
      name:"eye19",
      width:97,
      height:97,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye20left,
      name:"eye20-left",
      width:74,
      height:99,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye20right,
      name:"eye20-right",
      width:86,
      height:115,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye21,
      name:"eye21",
      width:56,
      height:74,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye22,
      name:"eye22",
      width:140,
      height:41,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye23left,
      name:"eye23-left",
      width:119,
      height:66,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye23right,
      name:"eye23-right",
      width:126,
      height:48,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye24left,
      name:"eye24-left",
      width:174,
      height:184,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye24right,
      name:"eye24-right",
      width:174,
      height:184,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye25left,
      name:"eye25-left",
      width:123,
      height:163,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye25right,
      name:"eye25-right",
      width:123,
      height:163,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye26left,
      name:"eye26-left",
      width:122,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye26right,
      name:"eye26-right",
      width:122,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye27left,
      name:"eye27-left",
      width:122,
      height:71,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye27right,
      name:"eye27-right",
      width:122,
      height:71,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye28,
      name:"eye28",
      width:125,
      height:41,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye29left,
      name:"eye29-left",
      width:102,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye29right,
      name:"eye29-right",
      width:102,
      height:69,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye30,
      name:"eye30",
      width:69,
      height:97,
      x:0,
      y:0,
      key: 0,
      target: false
    },
    {
      pass:eye31,
      name:"eye31",
      width:69,
      height:97,
      x:0,
      y:0,
      key: 0,
      target: false
    },
  ]
  return eyeMenuList
}

export default GetEyeMenuList;
