import base1 from '../img/base/base1.svg';
import base2 from '../img/base/base2.svg';
import base3 from '../img/base/base3.svg';
import base4 from '../img/base/base4.svg';
import base5 from '../img/base/base5.svg';
import base6 from '../img/base/base6.svg';
import base7 from '../img/base/base7.svg';
import base8 from '../img/base/base8.svg';
import base9 from '../img/base/base9.svg';
import base10 from '../img/base/base10.svg';
import base11 from '../img/base/base11.svg';
import eye1 from '../img/eye/eye1.svg';
import eye2 from '../img/eye/eye2.svg';
import eye3 from '../img/eye/eye3.svg';
import eye4 from '../img/eye/eye4.svg';
import eye5left from '../img/eye/eye5-left.svg';
import eye5right from '../img/eye/eye5-right.svg';
import eye6left from '../img/eye/eye6-left.svg';
import eye6right from '../img/eye/eye6-right.svg';
import eye7 from '../img/eye/eye7.svg';
import eye8 from '../img/eye/eye8.svg';
import eye9left from '../img/eye/eye9-left.svg';
import eye9right from '../img/eye/eye9-right.svg';
import eye10 from '../img/eye/eye10.svg';
import eye11 from '../img/eye/eye11.svg';
import eye12 from '../img/eye/eye12.svg';
import eye13 from '../img/eye/eye13.svg';
import eye14 from '../img/eye/eye14.svg';
import eye15 from '../img/eye/eye15.svg';
import eye16 from '../img/eye/eye16.svg';
import eye17 from '../img/eye/eye17.svg';
import eye18 from '../img/eye/eye18.svg';
import eye19 from '../img/eye/eye19.svg';
import eye20left from '../img/eye/eye20-left.svg';
import eye20right from '../img/eye/eye20-right.svg';
import eye21 from '../img/eye/eye21.svg';
import eye22 from '../img/eye/eye22.svg';
import eye23left from '../img/eye/eye23-left.svg';
import eye23right from '../img/eye/eye23-right.svg';
import eye24left from '../img/eye/eye24-left.svg';
import eye24right from '../img/eye/eye24-right.svg';
import eye25left from '../img/eye/eye25-left.svg';
import eye25right from '../img/eye/eye25-right.svg';
import eye26left from '../img/eye/eye26-left.svg';
import eye26right from '../img/eye/eye26-right.svg';
import eye27left from '../img/eye/eye27-left.svg';
import eye27right from '../img/eye/eye27-right.svg';
import eye28 from '../img/eye/eye28.svg';
import eye29left from '../img/eye/eye29-left.svg';
import eye29right from '../img/eye/eye29-right.svg';
import eye30 from '../img/eye/eye30.svg';
import eye31 from '../img/eye/eye31.svg';
import eyebrow1left from '../img/eyebrow/eyebrow1-left.svg';
import eyebrow1right from '../img/eyebrow/eyebrow1-right.svg';
import eyebrow2left from '../img/eyebrow/eyebrow2-left.svg';
import eyebrow2right from '../img/eyebrow/eyebrow2-right.svg';
import eyebrow3left from '../img/eyebrow/eyebrow3-left.svg';
import eyebrow3right from '../img/eyebrow/eyebrow3-right.svg';
import eyebrow4left from '../img/eyebrow/eyebrow4-left.svg';
import eyebrow4right from '../img/eyebrow/eyebrow4-right.svg';
import eyebrow5left from '../img/eyebrow/eyebrow5-left.svg';
import eyebrow5right from '../img/eyebrow/eyebrow5-right.svg';
import eyebrow6left from '../img/eyebrow/eyebrow6-left.svg';
import eyebrow6right from '../img/eyebrow/eyebrow6-right.svg';
import eyebrow7left from '../img/eyebrow/eyebrow7-left.svg';
import eyebrow7right from '../img/eyebrow/eyebrow7-right.svg';
import eyebrow8left from '../img/eyebrow/eyebrow8-left.svg';
import eyebrow8right from '../img/eyebrow/eyebrow8-right.svg';
import eyebrow9left from '../img/eyebrow/eyebrow9-left.svg';
import eyebrow10right from '../img/eyebrow/eyebrow10-right.svg';
import eyebrow11left from '../img/eyebrow/eyebrow11-left.svg';
import eyebrow12right from '../img/eyebrow/eyebrow12-right.svg';
import eyebrow13right from '../img/eyebrow/eyebrow13-right.svg';
import eyebrow14left from '../img/eyebrow/eyebrow14-left.svg';
import eyebrow15left from '../img/eyebrow/eyebrow15-left.svg';
import eyebrow15right from '../img/eyebrow/eyebrow15-right.svg';
import eyebrow16left from '../img/eyebrow/eyebrow16-left.svg';
import eyebrow16right from '../img/eyebrow/eyebrow16-right.svg';
import eyebrow17left from '../img/eyebrow/eyebrow17-left.svg';
import eyebrow17right from '../img/eyebrow/eyebrow17-right.svg';

import mouth1 from '../img/mouth/mouth1.svg';
import mouth2 from '../img/mouth/mouth2.svg';
import mouth3 from '../img/mouth/mouth3.svg';
import mouth4 from '../img/mouth/mouth4.svg';
import mouth5 from '../img/mouth/mouth5.svg';
import mouth6 from '../img/mouth/mouth6.svg';
import mouth7 from '../img/mouth/mouth7.svg';
import mouth8 from '../img/mouth/mouth8.svg';
import mouth9 from '../img/mouth/mouth9.svg';
import mouth10 from '../img/mouth/mouth10.svg';
import mouth11 from '../img/mouth/mouth11.svg';
import mouth12 from '../img/mouth/mouth12.svg';
import mouth13 from '../img/mouth/mouth13.svg';
import mouth14 from '../img/mouth/mouth14.svg';
import mouth15 from '../img/mouth/mouth15.svg';
import mouth16 from '../img/mouth/mouth16.svg';
import mouth17 from '../img/mouth/mouth17.svg';
import mouth18 from '../img/mouth/mouth18.svg';
import mouth19 from '../img/mouth/mouth19.svg';
import mouth20 from '../img/mouth/mouth20.svg';
import mouth21 from '../img/mouth/mouth21.svg';
import mouth22 from '../img/mouth/mouth22.svg';
import mouth23 from '../img/mouth/mouth23.svg';
import mouth24 from '../img/mouth/mouth24.svg';
import mouth25 from '../img/mouth/mouth25.svg';
import mouth26 from '../img/mouth/mouth26.svg';
import mouth27 from '../img/mouth/mouth27.svg';
import mouth28 from '../img/mouth/mouth28.svg';
import mouth29 from '../img/mouth/mouth29.svg';
import mouth30 from '../img/mouth/mouth30.svg';
import mouth31 from '../img/mouth/mouth31.svg';
import mouth32 from '../img/mouth/mouth32.svg';
import mouth33 from '../img/mouth/mouth33.svg';
import mouth34 from '../img/mouth/mouth34.svg';
import mouth35 from '../img/mouth/mouth35.svg';
import mouth36 from '../img/mouth/mouth36.svg';
import mouth37 from '../img/mouth/mouth37.svg';
import mouth38 from '../img/mouth/mouth38.svg';
import mouth39 from '../img/mouth/mouth39.svg';
import mouth40 from '../img/mouth/mouth40.svg';
import mouth41 from '../img/mouth/mouth41.svg';

import accessory1_1 from '../img/accessory/accessory1-1.svg';
import accessory1_2 from '../img/accessory/accessory1-2.svg';
import accessory1_3 from '../img/accessory/accessory1-3.svg';
import accessory2 from '../img/accessory/accessory2.svg';
import accessory3 from '../img/accessory/accessory3.svg';
import accessory4_1 from '../img/accessory/accessory4-1.svg';
import accessory4_2 from '../img/accessory/accessory4-2.svg';
import accessory4_3 from '../img/accessory/accessory4-3.svg';
import accessory4_4 from '../img/accessory/accessory4-4.svg';
import accessory5_1 from '../img/accessory/accessory5-1.svg';
import accessory5_2 from '../img/accessory/accessory5-2.svg';
import accessory5_3 from '../img/accessory/accessory5-3.svg';
import accessory6_1 from '../img/accessory/accessory6-1.svg';
import accessory6_2 from '../img/accessory/accessory6-2.svg';
import accessory7_1 from '../img/accessory/accessory7-1.svg';
import accessory7_2 from '../img/accessory/accessory7-2.svg';
import accessory7_3 from '../img/accessory/accessory7-3.svg';
import accessory8_1 from '../img/accessory/accessory8-1.svg';
import accessory8_2 from '../img/accessory/accessory8-2.svg';
import accessory8_3 from '../img/accessory/accessory8-3.svg';
import accessory8_4 from '../img/accessory/accessory8-4.svg';
import accessory9 from '../img/accessory/accessory9.svg';
import accessory10_1 from '../img/accessory/accessory10-1.svg';
import accessory10_2 from '../img/accessory/accessory10-2.svg';
import accessory10_3 from '../img/accessory/accessory10-3.svg';
import accessory10_4 from '../img/accessory/accessory10-4.svg';
import accessory11 from '../img/accessory/accessory11.svg';
import accessory12 from '../img/accessory/accessory12.svg';
import accessory13 from '../img/accessory/accessory13.svg';
import accessory14_1 from '../img/accessory/accessory14-1.svg';
import accessory14_2 from '../img/accessory/accessory14-2.svg';
import accessory15_1 from '../img/accessory/accessory15-1.svg';
import accessory15_2 from '../img/accessory/accessory15-2.svg';
import accessory16_1 from '../img/accessory/accessory16-1.svg';
import accessory16_2 from '../img/accessory/accessory16-2.svg';
import accessory17 from '../img/accessory/accessory17.svg';
import accessory18_1 from '../img/accessory/accessory18-1.svg';
import accessory18_2 from '../img/accessory/accessory18-2.svg';
import accessory18_3 from '../img/accessory/accessory18-3.svg';
import accessory18_4 from '../img/accessory/accessory18-4.svg';
import accessory18_5 from '../img/accessory/accessory18-5.svg';
import accessory18_6 from '../img/accessory/accessory18-6.svg';
import accessory19_1 from '../img/accessory/accessory19-1.svg';
import accessory19_2 from '../img/accessory/accessory19-2.svg';
import accessory19_3 from '../img/accessory/accessory19-3.svg';

type KetType = {
  [Key in string]: string;
};

const NameToPass = (key:string) => {
  let nameToPass:KetType = {
    "base1":base1,
    "base2":base2,
    "base3":base3,
    "base4":base4,
    "base5":base5,
    "base6":base6,
    "base7":base7,
    "base8":base8,
    "base9":base9,
    "base10":base10,
    "base11":base11,

    "eye1":eye1,
    "eye2":eye2,
    "eye3":eye3,
    "eye4":eye4,
    "eye5-left":eye5left,
    "eye5-right":eye5right,
    "eye6-left":eye6left,
    "eye6-right":eye6right,
    "eye7":eye7,
    "eye8":eye8,
    "eye9-left":eye9left,
    "eye9-right":eye9right,
    "eye10":eye10,
    "eye11":eye11,
    "eye12":eye12,
    "eye13":eye13,
    "eye14":eye14,
    "eye15":eye15,
    "eye16":eye16,
    "eye17":eye17,
    "eye18":eye18,
    "eye19":eye19,
    "eye20-left":eye20left,
    "eye20-right":eye20right,
    "eye21":eye21,
    "eye22":eye22,
    "eye23-left":eye23left,
    "eye23-right":eye23right,
    "eye24-left":eye24left,
    "eye24-right":eye24right,
    "eye25-left":eye25left,
    "eye25-right":eye25right,
    "eye26-left":eye26left,
    "eye26-right":eye26right,
    "eye27-left":eye27left,
    "eye27-right":eye27right,
    "eye28":eye28,
    "eye29-left":eye29left,
    "eye29-right":eye29right,
    "eye30":eye30,
    "eye31":eye31,

    "eyebrow1-left":eyebrow1left,
    "eyebrow1-right":eyebrow1right,
    "eyebrow2-left":eyebrow2left,
    "eyebrow2-right":eyebrow2right,
    "eyebrow3-left":eyebrow3left,
    "eyebrow3-right":eyebrow3right,
    "eyebrow4-left":eyebrow4left,
    "eyebrow4-right":eyebrow4right,
    "eyebrow5-left":eyebrow5left,
    "eyebrow5-right":eyebrow5right,
    "eyebrow6-left":eyebrow6left,
    "eyebrow6-right":eyebrow6right,
    "eyebrow7-left":eyebrow7left,
    "eyebrow7-right":eyebrow7right,
    "eyebrow8-left":eyebrow8left,
    "eyebrow8-right":eyebrow8right,
    "eyebrow9-left":eyebrow9left,
    "eyebrow10-right":eyebrow10right,
    "eyebrow11-left":eyebrow11left,
    "eyebrow12-right":eyebrow12right,
    "eyebrow13-right":eyebrow13right,
    "eyebrow14-left":eyebrow14left,
    "eyebrow15-left":eyebrow15left,
    "eyebrow15-right":eyebrow15right,
    "eyebrow16-left":eyebrow16left,
    "eyebrow16-right":eyebrow16right,
    "eyebrow17-left":eyebrow17left,
    "eyebrow17-right":eyebrow17right,

    "mouth1":mouth1,
    "mouth2":mouth2,
    "mouth3":mouth3,
    "mouth4":mouth4,
    "mouth5":mouth5,
    "mouth6":mouth6,
    "mouth7":mouth7,
    "mouth8":mouth8,
    "mouth9":mouth9,
    "mouth10":mouth10,
    "mouth11":mouth11,
    "mouth12":mouth12,
    "mouth13":mouth13,
    "mouth14":mouth14,
    "mouth15":mouth15,
    "mouth16":mouth16,
    "mouth17":mouth17,
    "mouth18":mouth18,
    "mouth19":mouth19,
    "mouth20":mouth20,
    "mouth21":mouth21,
    "mouth22":mouth22,
    "mouth23":mouth23,
    "mouth24":mouth24,
    "mouth25":mouth25,
    "mouth26":mouth26,
    "mouth27":mouth27,
    "mouth28":mouth28,
    "mouth29":mouth29,
    "mouth30":mouth30,
    "mouth31":mouth31,
    "mouth32":mouth32,
    "mouth33":mouth33,
    "mouth34":mouth34,
    "mouth35":mouth35,
    "mouth36":mouth36,
    "mouth37":mouth37,
    "mouth38":mouth38,
    "mouth39":mouth39,
    "mouth40":mouth40,
    "mouth41":mouth41,

    "accessory1-1":accessory1_1,
    "accessory1-2":accessory1_2,
    "accessory1-3":accessory1_3,
    "accessory2":accessory2,
    "accessory3":accessory3,
    "accessory4-1":accessory4_1,
    "accessory4-2":accessory4_2,
    "accessory4-3":accessory4_3,
    "accessory4-4":accessory4_4,
    "accessory5-1":accessory5_1,
    "accessory5-2":accessory5_2,
    "accessory5-3":accessory5_3,
    "accessory6-1":accessory6_1,
    "accessory6-2":accessory6_2,
    "accessory7-1":accessory7_1,
    "accessory7-2":accessory7_2,
    "accessory7-3":accessory7_3,
    "accessory8-1":accessory8_1,
    "accessory8-2":accessory8_2,
    "accessory8-3":accessory8_3,
    "accessory8-4":accessory8_4,
    "accessory9":accessory9,
    "accessory10-1":accessory10_1,
    "accessory10-2":accessory10_2,
    "accessory10-3":accessory10_3,
    "accessory10-4":accessory10_4,
    "accessory11":accessory11,
    "accessory12":accessory12,
    "accessory13":accessory13,
    "accessory14-1":accessory14_1,
    "accessory14-2":accessory14_2,
    "accessory15-1":accessory15_1,
    "accessory15-2":accessory15_2,
    "accessory16-1":accessory16_1,
    "accessory16-2":accessory16_2,
    "accessory17":accessory17,
    "accessory18-1":accessory18_1,
    "accessory18-2":accessory18_2,
    "accessory18-3":accessory18_3,
    "accessory18-4":accessory18_4,
    "accessory18-5":accessory18_5,
    "accessory18-6":accessory18_6,
    "accessory19-1":accessory19_1,
    "accessory19-2":accessory19_2,
    "accessory19-3":accessory19_3
  }
  return  key in nameToPass ? nameToPass[key] : "none";
}

export default NameToPass;
